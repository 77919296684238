import { useEffect, useState } from 'react';
import { Input } from '../../../common/Forms/Input/Input';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as schemas from '../../../../assets/schemas/schemas';
import { ActionButton } from '../../../common/Buttons/Buttons';
import {
  capitalize,
  filterParams,
  returnMissingFields,
  removeBlankSpacesAndUppercase, validateIfOther
} from '../../../../assets/helpers/funciones';
import { idCodes, affiliationTypeOptions, vehicleWeightOptions, vehiclesEnum } from '../../../../assets/helpers/options';
import { statesService } from '../../../../services/statesService';
import { vehicleService } from '../../../../services/vehicleService';
import { membershipService } from '../../../../services/membershipService';
import { economicActivitiesService } from '../../../../services/economicActivitiesService';
import { notify } from '../../../../assets/helpers/toast';
import { armoredOptions } from '../../../../assets/helpers/options';
import { OderCardHeader } from './OrderComponents';
import { MissingFieldsModal, ChangeVehicleModal, VehicleChangeHistoryModal } from '../../../common/Modals/Modals';
import { vehicleChangeHistoryHeader } from "../../../../assets/helpers/tables"
import { LoadSpinner } from '../../../common/LoadSpinner/LoadSpinner';
import { salesRoles } from '../../../../assets/helpers/roles';
import authService from '../../../../services/authService';
import './OrderComponent.scss';

export const VehicleInfoCard = (props) => {
  const { label, membership, reloadMembership } = props;
  const [statesOptions, setStatesOptions] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [economicActivitiesOptions, setEconomicActivitiesOptions] = useState([]);
  const [ownercitiesOptions, setOwnerCitiesOptions] = useState([]);
  const [vehicleChangeOwnercitiesOptions, setVehicleChangeOwnerCitiesOptions] = useState([]);
  const [customerIsOwner, setcustomerIsOwner] = useState('SI');
  const [vehicleBrandOptions, setVehicleBrandOptions] = useState([]);
  const [vehicleModelOptions, setVehicleModelOptions] = useState([]);
  const [vehicleChangeModelOptions, setVehicleChangeModelOptions] = useState([]);
  const [vehicleClassesOptions, setVehicleClassesOptions] = useState([]);
  const [vehicleChangeMunicipalitiesOptions, setVehicleChangeMunicipalitiesOptions] = useState([]);
  const [municipalitiesOptions, setMunicipalitiesOptions] = useState([]);
  const [typesOptions, setTypesOptions] = useState([]);
  const [vehicleChangetypesOptions, setVehicleChangeTypesOptions] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [changeVehicleModal, setChangeVehicleModal] = useState({
    isOpen: false
  });
  const [missingFieldsModal, setMissingFieldsModal] = useState({
    isOpen: false,
    missingFields: [],
  });
  const [vehicleHistoryModal, setVehicleHistoryModal] = useState({
    isOpen: false
  });


  const [open, setOpen] = useState(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schemas.orderVehicleInfoForm()),
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    watch: watch2,
    reset: reset2,
    setError: setError2
  } = useForm({
    resolver: yupResolver(schemas.changeVehicleSchema()),
  });


  const changeVehicle = handleSubmit2(async (data) => {
    let dataToPost = { ...data };

    if (validateTruck(watch2) && !data?.truckWeight) {
      setError2("truckWeight", { type: 'custom', message: 'Obligatorio' });
      return null
    }

    dataToPost.membershipId = membership?._id;

    dataToPost = filterParams(dataToPost);

    if (!isLoading) {
      setIsloading(true);
      membershipService
        .changeVehicle(dataToPost)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Cambio de vehiculo`, 'success');
            reloadMembership();
            setIsloading(false);
            handleChangeVehicleModal()
            window.location.reload()
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });


  const getEconomicActivities = () => {
    economicActivitiesService
      .getEconomicActivities()
      .then((response) => {
        if (response.status === 200) {
          setEconomicActivitiesOptions(response?.data?.activities)
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
  };



  const preloadForm = () => {
    if (membership?.vehicle?.customerIsOwner) {
      setcustomerIsOwner('SI');
    } else {
      setcustomerIsOwner('NO');
    }
    setValue('customerIsOwner', membership?.vehicle?.customerIsOwner);

    setValue('ownerFirstName', membership?.vehicle?.ownerFirstName);
    setValue('ownerLastName', membership?.vehicle?.ownerLastName);
    setValue(
      'ownerIdentificationType',
      membership?.vehicle?.ownerIdentificationType
    );
    setValue('ownerIdentification', membership?.vehicle?.ownerIdentification);
    setValue('ownerIdentification', membership?.vehicle?.ownerIdentification);
    setValue('engineSerial', membership?.vehicle?.engineSerial);
    setValue('profession', membership?.customer?.profession);
    setValue('truckWeight', membership?.vehicle?.truckWeight);


    Promise.allSettled([
      getVehicleModels(membership?.vehicle?.brandId, false),
      getSalesVehicleClasses(),
      getVehicleTypes(membership?.vehicle?.classId),
      getStates(),
      getOwnerCities(membership?.vehicle?.ownerStateId),
      getCities(membership?.vehicle?.stateId, false),
      getMunicipalities(membership?.vehicle?.cityId, false),
      getEconomicActivities()
    ]).then((r) => {
      setTimeout(() => {
        // Addres info
        setValue('state', membership?.vehicle?.stateId);
        setValue('city', membership?.vehicle?.cityId);
        setValue('municipality', membership?.vehicle?.municipalityId);
        setValue('address', membership?.vehicle?.address);
        setValue('addressReference', membership?.vehicle?.addressReference);

        setValue('model', membership?.vehicle?.modelId);
        setValue('class', membership?.vehicle?.classId);
        setValue('type', membership?.vehicle?.typeId);
        setValue('ownerCity', membership?.vehicle?.ownerCityId);
        setValue('ownerAddress', membership?.vehicle?.ownerAddress);
        setValue('ownerState', membership?.vehicle?.ownerStateId);
        setValue('plate', membership?.vehicle?.plate);
        setValue('brand', membership?.vehicle?.brandId);
        setValue('color', capitalize(membership?.vehicle?.color));
        setValue('km', membership?.vehicle?.km);
        setValue('bodySerial', membership?.vehicle?.bodySerial);
        setValue('year', membership?.vehicle?.year);
        setValue('armored', membership?.vehicle?.armored);
        setValue('economicActivity', membership?.customer?.economicActivity);
        setValue('otherEconomicActivity', membership?.customer?.otherEconomicActivity);
      }, 3000);
    });
  };

  const editVehicle = handleSubmit(async (data) => {
    let dataToPost = { ...data };

    dataToPost.membership = membership?._id;

    delete dataToPost?.contractType;
    delete dataToPost?.affiliationType;

    dataToPost = filterParams(dataToPost);

    if (!isLoading) {
      setIsloading(true);
      vehicleService
        .updateVehicle(dataToPost, membership?.vehicle?._id)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Datos actualizados`, 'success');
            reloadMembership();
            setIsloading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const getVehicleBrands = () => {
    vehicleService
      .getVehicleBrands()
      .then((response) => {
        if (response.status === 200) {
          setVehicleBrandOptions(response?.data?.vehicleBrands);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(() => { });
  };

  const getSalesVehicleClasses = () => {
    vehicleService
      .getSalesVehicleClasses()
      .then((response) => {
        if (response.status === 200) {
          setVehicleClassesOptions(response?.data?.classes);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
  };

  const getVehicleTypes = (vehicleClass, changeVehicle) => {
    if (vehicleClass) {
      vehicleService
        .getVehicleTypes({
          class: vehicleClass ? vehicleClass : watch('class'),
        })
        .then((response) => {
          if (response.status === 200) {
            if (changeVehicle) {
              setVehicleChangeTypesOptions(response?.data?.types)
            } else {
              setTypesOptions(response?.data?.types);

            }
          } else {
            notify(`${response.message}` || 'error', 'error');
          }
        })
        .catch(() => { });
    }
  };

  const getVehicleModels = (brand, changeVehicle) => {
    vehicleService
      .getVehicleModels(brand ? brand : watch('brand'))
      .then((response) => {
        if (response.status === 200) {

          if (changeVehicle) {
            setVehicleChangeModelOptions(response?.data?.models);
          } else {
            setVehicleModelOptions(response?.data?.models);
          }

        } else {
          notify(`${response.message}`, 'error');
        }
      });
  };

  const getStates = () => {
    statesService
      .getStates()
      .then((response) => {
        if (response.status === 200) {
          setStatesOptions(response?.data?.states);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
  };

  const getOwnerCities = (ownerState) => {
    if (ownerState || watch('ownerState')) {
      statesService
        .getCities(ownerState ? ownerState : watch('ownerState'))
        .then((response) => {
          if (response.status === 200) {
            setOwnerCitiesOptions(response?.data?.cities);
          } else {
            notify(`${response.message}` || 'error', 'error');
          }
        })
        .catch(() => { });
      return true;
    }
  };

  const getCities = (state, changeVehicle) => {
    if (state || watch('city')) {
      statesService
        .getCities(state ? state : watch('state'))
        .then((response) => {
          if (response.status === 200) {
            if (changeVehicle) {
              setVehicleChangeOwnerCitiesOptions(response?.data?.cities)
            } else {
              setCitiesOptions(response?.data?.cities);
            }
          } else {
            notify(`${response.message}` || 'error', 'error');
          }
        })
        .catch(() => { });
      return true;
    }
  };

  const getMunicipalities = (city, changeVehicle) => {
    if (city || watch('city')) {
      statesService
        .getMunicipalities(city ? city : watch('city'))
        .then((response) => {
          if (response.status === 200) {
            if (changeVehicle) {
              setVehicleChangeMunicipalitiesOptions(response?.data?.municipalities);
            } else {
              setMunicipalitiesOptions(response?.data?.municipalities);
            }
          } else {
            notify(`${response.message}` || 'error', 'error');
          }
        })
        .catch(() => { });
      return true;
    }
  };

  const handlecustomerIsOwner = (e) => {
    if (e?.target?.value === 'SI') {
      setValue('customerIsOwner', true);
      setcustomerIsOwner(e?.target?.value);
      // clearTitularForm();
    }
    if (e?.target?.value === 'NO') {
      setValue('customerIsOwner', false);
      setcustomerIsOwner(e?.target?.value);
      // clearTitularForm();
    }
  };

  const handleMissingFieldModal = (missingFields) => {
    setMissingFieldsModal((prevState) => {
      return {
        ...prevState,
        isOpen: !missingFieldsModal?.isOpen,
        missingFields: missingFields ? missingFields : [],
      };
    });
  };

  const validatePlateAndYear = () => {
    return false;
  };

  const checkMissingValues = () => {
    const requiredFields = {
      class: membership?.vehicle?.classId,
      type: membership?.vehicle?.typeId,
      brand: membership?.vehicle?.brandId,
      municipality: membership?.vehicle?.municipalityId,
      model: membership?.vehicle?.modelId,
      state: membership?.vehicle?.stateId,
      city: membership?.vehicle?.cityId,
      address: membership?.vehicle?.address,
    };

    // Required Values
    const labels = [
      { label: 'class', name: 'Clase' },
      { label: 'type', name: 'Tipo' },
      { label: 'model', name: 'Modelo' },
      { label: 'brand', name: 'Marca' },
      { label: 'state', name: 'Estado' },
      { label: 'city', name: 'Genero' },
      { label: 'municipality', name: 'Municipio' },
      { label: 'address', name: 'Dirección' },
    ];

    let missingFields = returnMissingFields(labels, requiredFields);
    if (missingFields?.length > 0) {
      handleMissingFieldModal(missingFields);
    }
  };
  const validateTruck = (watch) => {

    const [camion] = vehicleClassesOptions?.filter((option) => [vehiclesEnum?.CAMION]?.includes(option.name))

    if (watch("class") === camion?._id) {
      return true
    } else return false
  }

  const handleChangeVehicleModal = () => {
    setChangeVehicleModal((prevState) => {
      return {
        ...prevState,
        isOpen: !changeVehicleModal?.isOpen,
      };
    });
  }

  const handleVehicleHistoryModal = () => {
    setVehicleHistoryModal((prevState) => {
      return {
        ...prevState,
        isOpen: !vehicleHistoryModal?.isOpen,
      };
    });
  }



  useEffect(() => {
    getVehicleBrands();
    preloadForm();
    checkMissingValues();
  }, [open]);

  return (
    <>

      <ChangeVehicleModal
        modal={changeVehicleModal}
        handleModal={handleChangeVehicleModal}
        register={register2}
        errors={errors2}
        action={changeVehicle}
        validateTruck={validateTruck}
        reset={reset2}
        watch={watch2}
        onChange={{ getVehicleModels: getVehicleModels, getVehicleTypes: getVehicleTypes, getCities: getCities, getMunicipalities: getMunicipalities }}
        options={{
          vehicleClassesOptions: vehicleClassesOptions,
          vehicleBrandOptions: vehicleBrandOptions,
          vehicleChangeModelOptions: vehicleChangeModelOptions,
          vehicleChangetypesOptions: vehicleChangetypesOptions,
          statesOptions: statesOptions,
          affiliationTypeOptions: affiliationTypeOptions,
          armoredOptions: armoredOptions,
          vehicleChangeOwnercitiesOptions: vehicleChangeOwnercitiesOptions,
          vehicleChangeMunicipalitiesOptions: vehicleChangeMunicipalitiesOptions,
          vehicleWeightOptions: vehicleWeightOptions
        }}
      />

      <VehicleChangeHistoryModal
        modal={vehicleHistoryModal}
        handleModal={handleVehicleHistoryModal}
        header={vehicleChangeHistoryHeader}
        data={membership?.vehicleHistory}
      />


      <LoadSpinner show={isLoading} />
      <MissingFieldsModal
        modal={missingFieldsModal}
        handleModal={handleMissingFieldModal}
      />
      <div className="order-card">
        <OderCardHeader open={open} setOpen={setOpen} label={label} />

        {open && (
          <>

            <div className="change-flow-button-container">
              {membership?.vehicleHistory?.length > 0 ? <ActionButton label={"Historico de Vehiculos"} action={handleVehicleHistoryModal} /> : <span></span>}
              {[salesRoles?.SALES_ADMIN, salesRoles?.SALES_DEVELOPER, salesRoles?.SALES_HUNTER, salesRoles?.SALES_FARMER]?.includes(authService?.getUserType())}
              <ActionButton label={"Cambio de Vehiculo"} action={handleChangeVehicleModal} />
            </div>


            <br />
            <div className="buttons-container">
              <h6 style={{ margin: '6px 12px 12px 0px' }}>
                DATOS DEL VEHÍCULO
              </h6>
              <ActionButton label={'Guardar'} action={editVehicle} />
            </div>
            <p style={{ margin: '0px' }}>(*) Campos obligatorios</p>
            <div style={{ width: '100%' }} className="row">
              <Input
                bootstrap={'col-12 col-md-4'}
                label={'Clase *'}
                type={'select'}
                options={vehicleClassesOptions}
                name={'class'}
                register={register}
                placeholder={''}
                onChange={() => getVehicleTypes(watch('class'))}
                errors={errors?.class?.message}
              />
              <Input
                bootstrap={'col-12 col-md-4'}
                label={'Marca *'}
                type={'select'}
                options={vehicleBrandOptions}
                name={'brand'}
                register={register}
                placeholder={''}
                errors={errors?.brand?.message}
                onChange={() => getVehicleModels(watch('brand'), false)}
              />
              <Input
                bootstrap={'col-12 col-md-4'}
                label={'Modelo *'}
                type={'select'}
                options={vehicleModelOptions}
                name={'model'}
                register={register}
                placeholder={''}
                errors={errors?.model?.message}
              />
              <Input
                bootstrap={'col-12 col-md-4'}
                label={'Tipo *'}
                type={'select'}
                options={typesOptions}
                name={'type'}
                register={register}
                placeholder={''}
                errors={errors?.type?.message}
              />
              {validateTruck(watch) && <Input
                bootstrap={'col-12 col-md-4'}
                label={'Peso del Camion *'}
                type={"select"}
                options={vehicleWeightOptions}
                name={'truckWeight'}
                register={register}
                placeholder={''}
                errors={errors?.truckWeight?.message}
              />}
              <Input
                bootstrap={'col-12 col-md-4'}
                label={'Año *'}
                type={'number'}
                name={'year'}
                register={register}
                placeholder={''}
                errors={errors?.year?.message}
                min={'1990'}
                max={new Date().getFullYear() + 1}
                disabled={validatePlateAndYear()}
              />
              <Input
                bootstrap={'col-12 col-md-4'}
                label={'Color *'}
                type={'text'}
                name={'color'}
                register={register}
                placeholder={''}
                errors={errors?.color?.message}
              />
              <Input
                bootstrap={'col-12 col-md-4'}
                label={'Placa *'}
                type={'text'}
                name={'plate'}
                register={register}
                placeholder={''}
                errors={errors?.plate?.message}
                onChange={(e) => removeBlankSpacesAndUppercase(watch('plate'), 'plate', setValue)}
                disabled={validatePlateAndYear()}
              />
              <Input
                bootstrap={'col-12 col-md-4'}
                label={'Kilometraje del Vehículo *'}
                type={'number'}
                name={'km'}
                register={register}
                placeholder={''}
                errors={errors?.km?.message}
                min={'0'}
              />
              <Input
                bootstrap={'col-12 col-md-4'}
                label={'Serial Carrocería *'}
                type={'text'}
                name={'bodySerial'}
                register={register}
                placeholder={''}
                errors={errors?.bodySerial?.message}
              />{' '}
              <Input
                bootstrap={'col-12 col-md-4'}
                label={'Blindaje *'}
                type={'select'}
                name={'armored'}
                options={armoredOptions}
                register={register}
                placeholder={''}
                errors={errors?.armored?.message}
              />
              <Input
                bootstrap={'col-12 col-md-4'}
                label={'Estado *'}
                type={'select'}
                options={statesOptions}
                name={'state'}
                register={register}
                placeholder={''}
                errors={errors?.state?.message}
                onChange={() => getCities(watch('state'), false)}
              />{' '}
              <Input
                bootstrap={'col-12 col-md-4'}
                label={'Ciudad *'}
                type={'select'}
                options={citiesOptions}
                name={'city'}
                register={register}
                placeholder={''}
                errors={errors?.city?.message}
                onChange={() => getMunicipalities(watch('city'), false)}
              />{' '}
              <Input
                bootstrap={'col-12 col-md-4'}
                label={'Municipio'}
                type={'select'}
                options={municipalitiesOptions}
                name={'municipality'}
                register={register}
                placeholder={''}
                errors={errors?.municipality?.message}
              />
              <Input
                bootstrap={'col-12 col-md-4'}
                label={'Dirección *'}
                type={'textarea'}
                name={'address'}
                register={register}
                placeholder={''}
                errors={errors?.address?.message}
              />
              <Input
                bootstrap={'col-12 col-md-4'}
                label={'Referencia *'}
                type={'textarea'}
                name={'addressReference'}
                register={register}
                placeholder={''}
                errors={errors?.addressReference?.message}
              />

              <Input
                bootstrap={'col-12 col-md-4'}
                label={'Serial Motor '}
                type={'text'}
                name={'engineSerial'}
                register={register}
                placeholder={''}
                errors={errors?.engineSerial?.message}
              />
              <Input
                bootstrap={'col-12 col-md-4'}
                label={'Profesión/Ocupación '}
                type={'text'}
                name={'profession'}
                register={register}
                placeholder={''}
                errors={errors?.profession?.message}
              />
              <Input
                bootstrap={'col-12 col-md-4'}
                label={'Actividad Económica'}
                type={'select'}
                options={economicActivitiesOptions}
                name={'economicActivity'}
                register={register}
                placeholder={''}
                onChange={() => setValue("otherEconomicActivity", "")}
                errors={errors?.economicActivity?.message}
              />
              {(validateIfOther(watch, economicActivitiesOptions) || membership?.customer?.otherEconomicActivity) &&
                <Input
                  bootstrap={'col-12 col-md-4'}
                  label={'Otra Actividad Económica *'}
                  type={'text'}
                  name={'otherEconomicActivity'}
                  register={register}
                  placeholder={''}
                  errors={errors?.otherEconomicActivity?.message}
                />
              }



              <div className="col-12 col-md-6 checbox-input-container">
                <label htmlFor="">¿Eres el titular del vehículo?</label>
                <div className="checbox-input-container-midle">
                  <div className="topping">
                    <input
                      type="checkbox"
                      id="topping"
                      name="customerIsOwner"
                      value={'SI'}
                      checked={customerIsOwner === 'SI'}
                      onChange={handlecustomerIsOwner}
                    />
                    Si
                  </div>{' '}
                  <div className="topping">
                    <input
                      type="checkbox"
                      id="topping"
                      name="customerIsOwner"
                      value={'NO'}
                      checked={customerIsOwner === 'NO'}
                      onChange={handlecustomerIsOwner}
                    />
                    No
                  </div>
                </div>
              </div>
            </div>
            {customerIsOwner === 'NO' && (
              <>
                <h6>DATOS DEL TITULAR</h6>
                <p style={{ margin: '0px' }}>(*) Campos obligatorios</p>
                <div style={{ width: '100%' }} className="row">
                  <Input
                    bootstrap={'col-12 col-md-4'}
                    label={'Nombre *'}
                    type={'text'}
                    name={'ownerFirstName'}
                    register={register}
                    placeholder={''}
                    errors={errors?.ownerFirstName?.message}
                  />{' '}
                  <Input
                    bootstrap={'col-12 col-md-4'}
                    label={'Apellido *'}
                    type={'text'}
                    name={'ownerLastName'}
                    register={register}
                    placeholder={''}
                    errors={errors?.ownerLastName?.message}
                  />
                  <Input
                    bootstrap={'col-4 col-sm-1 col-md-1'}
                    label={'C.I / RIF*'}
                    type={'select'}
                    name={'ownerIdentificationType'}
                    register={register}
                    placeholder={''}
                    options={idCodes}
                    errors={errors?.ownerIdentificationType?.message}
                  />{' '}
                  <Input
                    bootstrap={'col-8 col-sm-3 col-md-3'}
                    label={'del titular*'}
                    type={'number'}
                    name={'ownerIdentification'}
                    register={register}
                    placeholder={''}
                    errors={errors?.ownerIdentification?.message}
                  />{' '}
                  <Input
                    bootstrap={'col-12 col-md-4'}
                    label={'Estado *'}
                    type={'select'}
                    options={statesOptions}
                    name={'ownerState'}
                    register={register}
                    placeholder={''}
                    errors={errors?.ownerState?.message}
                    onChange={() => getOwnerCities(watch('ownerState'))}
                  />
                  <Input
                    bootstrap={'col-12 col-md-4'}
                    label={'Ciudad *'}
                    type={'select'}
                    options={ownercitiesOptions}
                    name={'ownerCity'}
                    register={register}
                    placeholder={''}
                    errors={errors?.ownerCity?.message}
                  />{' '}
                  <Input
                    bootstrap={'col-12 col-md-4'}
                    label={'Dirección de domicilio *'}
                    type={'textarea'}
                    name={'ownerAddress'}
                    register={register}
                    placeholder={''}
                    errors={errors?.ownerAddress?.message}
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};
