import React, { useState, useCallback, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import * as schemas from '../../../assets/schemas/schemas';
import {
  FollowingForm,
  GiftcardRetailForm,
  FleetLoadForm,
  GiftcardParticularForm,
  ApprovalPaymentForm,
  CancelServiceForm,
  CreateAVForm,
  FinishServiceForm,
  CreateTowForm,
  CreateFleetForm,
  CreateCodeForm,
  CreateGiftcardSaleForm,
  EditCodeForm,
  ActivateGiftcardForm,
  CancelFleetForm,
  CreateSalesUserForm,
  ChangeUserForm,
  ChangeVehicleForm,
  EditSalesUserForm,
  NewPaymentModal,
  EditPaymentForm,
  UpgradeMembershipForm,
  RenewFleetLoadForm,
  RenewInforForm,
  FleetLoadPaymentForm,
  EditGiftcardSaleForm,
  CancelMembershipForm,
  PendingPaymentForm,
} from '../Forms/Form';
import {
  SelectProvider,
  Input,
  FilterDatePicker,
} from '../../common/Forms/Input/Input';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { TextComponent } from '../../views/AffiliationsDB/Order/OrderComponents';
import { AltButton, ActionButton } from '../Buttons/Buttons';
import { Dropzone, FileComponent, NewDropzone } from '../Dropzone/Dropzone';
import { ScheduleServicesForm } from '../../common/Forms/Form';
import {
  HistoryPaymentTable,
  MembershipHistoryTable,
  PaymentPlanTable,
} from '../../common/Tables/Tables';
import {
  historyPaymentTableHeader,
  historyPaymentMockup,
  serviceHistoryTableheader,
  paymentPlanTableHeader,
} from '../../../assets/helpers/tables';
import { membershipService } from '../../../services/membershipService';
import { fleetLoadService } from '../../../services/fleetLoadService';
import { giftCardService } from '../../../services/giftCardService';
import { fleetService } from '../../../services/fleetService';
import { discountCodesServices } from '../../../services/discountCodesServices';
import { caseService } from '../../../services/caseService';
import { servicesService } from '../../../services/servicesService';
import { notify } from '../../../assets/helpers/toast';
import {
  exists,
  capitalize,
  filterParams,
  exportDataToExcel, displayChange
} from '../../../assets/helpers/funciones';
import { smsText } from '../../../assets/helpers/smsText';
import { useJsApiLoader } from '@react-google-maps/api';
import {
  cancelFleatLoadOptions,
  giftCardType,
  paymentMethods,
  planTypes
} from '../../../assets/helpers/options';
import { Map } from './Map';
import moment from 'moment';
import { useDropzone } from 'react-dropzone';
import Pagination from '@material-ui/lab/Pagination';
import './Modals.scss';

export const ScheduleServicesModal = (props) => {
  const { show, register, errors, handleClose, t, action, control } = props;

  return (
    <Modal size="lg" centered show={show} onHide={() => handleClose(false)}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>Agendar Servicio</h5>
        <h6 style={{ textAlign: 'center' }}>
          Seleccione la fecha y hora para la cual desea agendar su servicio
        </h6>
        <ScheduleServicesForm
          t={t}
          register={register}
          errors={errors}
          action={action}
          control={control}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const FollowingModal = (props) => {
  const { showFollowingModal, handleClose, refresh } = props;

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemas.UpdateTrackingSchema(t)),
  });
  const [isLoading, setIsloading] = useState(false);
  // Function that handles the logout request
  const onSubmit = handleSubmit(async (data) => {
    data.stage = showFollowingModal?.selected?.status;
    if (!isLoading) {
      setIsloading(true);
      caseService
        .updateCaseTracking(data, showFollowingModal?.selected?._id)
        .then((response) => {
          if (response.status === 200) {
            handleClose(true);
            notify(`Seguimiento añadido con éxito`, 'success');
            refresh();
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  return (
    <Modal
      size="lg"
      centered
      show={showFollowingModal?.isOpen}
      onHide={() => handleClose(false)}
    >
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>Registrar seguimiento</h5>
        <FollowingForm
          t={t}
          register={register}
          errors={errors}
          action={onSubmit}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const RetailModal = (props) => {
  const { show, handleClose } = props;

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schemas.LoginSchema(t)),
  });
  let history = useHistory();
  // Function that handles the logout request
  const onSubmit = handleSubmit(async (data) => {
    history.push('/services');
  });

  return (
    <Modal size="lg" centered show={show} onHide={() => handleClose(false)}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h2>Nueva Venta</h2>
        <GiftcardRetailForm
          t={t}
          register={register}
          errors={errors}
          action={onSubmit}
          control={control}
          cancel={() => handleClose(false)}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const CancelServiceModal = (props) => {
  const { showCancelService, handleClose, refresh } = props;

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schemas.CancelServiceSchema(t)),
  });
  const [isLoading, setIsloading] = useState(false);
  // Function that handles the logout request
  const onSubmit = handleSubmit(async (data) => {
    if (!isLoading) {
      setIsloading(true);
      caseService
        .cancelCase(data, showCancelService?.selected?._id)
        .then((response) => {
          if (response.status === 200) {
            notify(`Caso cancelado con éxito`, 'success');
            setIsloading(false);
            handleClose(false);
            refresh();
            reset();
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const close = () => {
    handleClose(false);
    refresh();
    reset();
  };

  return (
    <Modal
      size="lg"
      centered
      show={showCancelService?.show}
      onHide={() => close()}
    >
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>Cancelar Membresia</h5>
        <CancelServiceForm
          t={t}
          register={register}
          errors={errors}
          action={onSubmit}
          cancel={() => close()}
          cancelOptions={showCancelService?.cancelOptions}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const CancelMembershipModal = (props) => {
  const { showCancelService, handleClose, refresh } = props;

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schemas.CancelMembershipSchema(t)),
  });
  const [isLoading, setIsloading] = useState(false);
  // Function that handles the logout request
  const onSubmit = handleSubmit(async (data) => {
    if (!isLoading) {
      setIsloading(true);
      membershipService
        .cancelMembership(data, showCancelService?.caseSelected?._id)
        .then((response) => {
          if (response.status === 200) {
            notify(`Membresia anulada con éxito`, 'success');
            setIsloading(false);
            handleClose(false);
            refresh();
            reset();
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const close = () => {
    handleClose(false);
    refresh();
    reset();
  };

  return (
    <Modal
      size="lg"
      centered
      show={showCancelService?.show}
      onHide={() => close()}
    >
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>Anular Membresia</h5>
        <div className="buttons-center-container">
          <h6>¿ Esta seguro que desea anular la membresia ?</h6>
        </div>
        <CancelMembershipForm
          t={t}
          register={register}
          errors={errors}
          action={onSubmit}
          cancel={() => close()}
          cancelOptions={showCancelService?.cancelOptions}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const ChangeOperatorModal = (props) => {
  const { showChangeOperatorModal, handleClose, refresh, options } = props;
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(schemas.ChangeOperatorSchema(t)),
  });
  const [isLoading, setIsloading] = useState(false);
  // Function that handles the logout request
  const onSubmit = handleSubmit(async (data) => {
    if (!isLoading) {
      setIsloading(true);
      caseService
        .changeOperator(data, showChangeOperatorModal?.selected?._id)
        .then((response) => {
          if (response.status === 200) {
            notify(`Operador modificado con exito`, 'success');
            setIsloading(false);
            handleClose(false);
            refresh();
            reset();
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  let close = () => {
    reset();
    handleClose();
  };

  let filteredOperators = options?.filter(
    (o) => o?._id !== showChangeOperatorModal?.selected?.user?._id
  );

  return (
    <Modal
      size="lg"
      centered
      show={showChangeOperatorModal?.isOpen}
      onHide={() => handleClose(false)}
    >
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>
          Cambiar Operador para el caso{' '}
          {showChangeOperatorModal?.selected?.caseId}
        </h5>

        <form className="form-container">
          <div className="row">
            <Input
              bootstrap={'col-12'}
              label={'Listado de Operadores*'}
              type={'select'}
              options={filteredOperators}
              name={'user'}
              register={register}
              placeholder={''}
              errors={errors?.user?.message}
            />{' '}
          </div>
          <div className="buttons-center-container">
            {watch('user') && (
              <p style={{ textAlign: 'center' }}>
                ¿Está seguro que desea cambiar el operador de <br />
                <b>
                  {showChangeOperatorModal?.selected?.user?.firstName +
                    '  ' +
                    showChangeOperatorModal?.selected?.user?.lastName}
                </b>
                {'   '}a{'  '}
                <b>
                  {options.filter((o) => o?._id === watch('user'))[0]?.name}
                </b>{' '}
                ?
              </p>
            )}{' '}
          </div>
          <div className="buttons-center-container">
            <AltButton action={() => close()} label={'No'} />
            {watch('user') ? (
              <ActionButton action={onSubmit} label={'Si, cambiar operado'} />
            ) : (
              <span></span>
            )}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const FinishCaseModal = (props) => {
  const { handleClose, options, refresh, finishCaseModal } = props;

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schemas.FinishServiceSchema(t)),
  });
  const [isLoading, setIsloading] = useState(false);
  // Function that handles the logout request
  const onSubmit = handleSubmit(async (data) => {
    let dataToPost = { ...data };

    dataToPost.solvedByAV = false;
    dataToPost.stage = finishCaseModal?.selected?.lastStage?._id;
    if (!isLoading) {
      setIsloading(true);
      caseService
        .finishCase(dataToPost, finishCaseModal?.selected?._id)
        .then((response) => {
          if (response.status === 200) {
            notify(`Caso Finalizado con éxito`, 'success');
            setIsloading(false);
            handleClose(false);
            reset();
            refresh();
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  return (
    <Modal
      size="lg"
      centered
      show={finishCaseModal?.isOpen}
      onHide={() => handleClose(false)}
    >
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>Finalizar Servicio</h5>
        <FinishServiceForm
          t={t}
          register={register}
          errors={errors}
          action={onSubmit}
          cancel={() => handleClose(false)}
          options={options}
          actionLabel="Finalizar Servicio"
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const SolvedByAVModal = (props) => {
  const { handleClose, options, refresh, solvedByAVModal } = props;

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schemas.FinishServiceSchema(t)),
  });
  const [isLoading, setIsloading] = useState(false);
  // Function that handles the logout request
  const onSubmit = handleSubmit(async (data) => {
    let dataToPost = { ...data };

    dataToPost.solvedByAV = true;
    dataToPost.stage = solvedByAVModal?.selected?.lastStage?._id;
    if (!isLoading) {
      setIsloading(true);
      caseService
        .finishCase(dataToPost, solvedByAVModal?.selected?._id)
        .then((response) => {
          if (response.status === 200) {
            notify(`Caso Finalizado con éxito`, 'success');
            setIsloading(false);
            handleClose(false);
            reset();
            refresh();
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  return (
    <Modal
      size="lg"
      centered
      show={solvedByAVModal?.isOpen}
      onHide={() => handleClose(false)}
    >
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>Falla Resuelta por Motorizado</h5>
        <FinishServiceForm
          t={t}
          register={register}
          errors={errors}
          action={onSubmit}
          cancel={() => handleClose(false)}
          options={options}
          actionLabel="Falla resuelta por motorizado"
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};
export const FleetLoadModal = (props) => {
  const { show, handleClose } = props;

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schemas.FleetLoadSchema(t)),
  });
  // Function that handles the logout request
  const onSubmit = handleSubmit(async (data) => {
    handleClose(false);
  });

  return (
    <Modal size="lg" centered show={show} onHide={() => handleClose(false)}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h2>Datos de Flota</h2>
        <FleetLoadForm
          t={t}
          register={register}
          errors={errors}
          action={onSubmit}
          control={control}
          cancel={() => handleClose(false)}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};


export const PayHistoryModal = (props) => {
  const { show, handleClose } = props;

  return (
    <Modal size="lg" centered show={show} onHide={() => handleClose(false)}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h2>Historial de pagos</h2>
        <HistoryPaymentTable
          header={historyPaymentTableHeader}
          data={historyPaymentMockup}
        />
      </Modal.Body>
      <div className="buttons-center-container">
        <div style={{ textAlign: 'center' }}>
          <h6>Cuentas pagadas</h6>
          <AltButton label={'1000'} />
        </div>
        <div style={{ textAlign: 'center' }}>
          <h6>Cuentas por Cobrar</h6>
          <AltButton label={'1000'} />
        </div>
      </div>

      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};



export const ParticularModal = (props) => {
  const { show, handleClose } = props;

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schemas.LoginSchema(t)),
  });
  let history = useHistory();
  // Function that handles the logout request
  const onSubmit = handleSubmit(async (data) => {
    history.push('/services');
  });

  return (
    <Modal size="lg" centered show={show} onHide={() => handleClose(false)}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h2>Nueva Venta</h2>
        <GiftcardParticularForm
          t={t}
          register={register}
          errors={errors}
          action={onSubmit}
          control={control}
          cancel={() => handleClose(false)}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const LogOutModal = (props) => {
  const { handleModal, modal, action } = props;

  return (
    <Modal
      size="lg"
      centered
      show={modal?.isOpen}
      onHide={() => handleModal(false)}
    >
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5> ¿Está seguro que desea cerrar sesión ?</h5>
      </Modal.Body>
      <br />
      <br />
      <div className="buttons-center-container">
        <AltButton label={'No'} action={() => handleModal()} />
        <ActionButton action={() => action()} label={'Si'} />
      </div>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const MissingFieldsModal = (props) => {
  const { handleModal, modal } = props;

  return (
    <Modal
      size="lg"
      centered
      show={modal?.isOpen}
      onHide={() => handleModal(false)}
    >
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5 style={{ textAlign: 'center' }}>
          Los siguientes campos de la membresia estan incompletos o tienen un
          valor incorrecto
        </h5>
      </Modal.Body>
      <h4 style={{ textAlign: 'center' }}>
        {modal?.missingFields?.map((m) => (
          <>
            <span style={{ marginBottom: '12px' }}>{m?.name + '   '}</span>
            <br />
          </>
        ))}
      </h4>
      <br />
      <div className="buttons-center-container">
        <ActionButton action={() => handleModal()} label={'Aceptar'} />
      </div>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const ChangeProviderModal = (props) => {
  const { handleClose, options, changeProviderModal, value, onChange, action } =
    props;

  let filteredBikers = options?.filter(
    (o) => o?._id !== changeProviderModal?.selected?.lastService?.provider?._id
  );

  return (
    <Modal
      size="lg"
      centered
      show={changeProviderModal?.isOpen}
      onHide={() => handleClose(false)}
    >
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>
          Cambiar motorizado para el caso{' '}
          {changeProviderModal?.selected?.caseId}
        </h5>
        <SelectProvider
          bootstrap={'col-12 col-md-6'}
          label={'Elegir Motorizado:'}
          type={'select'}
          options={filteredBikers}
          name={'motorizado'}
          placeholder={'Seleccione'}
          value={value}
          onChange={(e) => onChange(e?.target?.value)}
        />
        {value && (
          <p style={{ textAlign: 'center' }}>
            ¿Está seguro que desea cambiar el motorizado de <br />
            <b>
              {
                changeProviderModal?.selected?.lastService?.provider
                  ?.contactName
              }
            </b>
            {'   '}a{'  '}
            <b>{options.filter((o) => o?._id === value)[0]?.contactName}</b> ?
          </p>
        )}

        <br />
        <div className="buttons-center-container">
          <AltButton label={'No'} action={() => handleClose(false)} />{' '}
          {value && (
            <ActionButton
              action={() => action()}
              label={'Cambiar motorizado'}
            />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const ChangeTowModal = (props) => {
  const { handleClose, options, changeProviderModal, value, onChange, action } =
    props;

  let filteredTows = options?.filter(
    (o) => o?._id !== changeProviderModal?.selected?.lastService?.provider?._id
  );

  return (
    <Modal
      size="lg"
      centered
      show={changeProviderModal?.isOpen}
      onHide={() => handleClose(false)}
    >
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>
          Cambiar Gruero para el caso {changeProviderModal?.selected?.caseId}
        </h5>
        <SelectProvider
          bootstrap={'col-12 col-md-6'}
          label={'Elegir Gruero:'}
          type={'select'}
          options={filteredTows}
          name={'motorizado'}
          placeholder={'Seleccione'}
          value={value}
          onChange={(e) => onChange(e?.target?.value)}
        />
        {value && (
          <p style={{ textAlign: 'center' }}>
            ¿Está seguro que desea cambiar el gruero de <br />
            <b>
              {
                changeProviderModal?.selected?.lastService?.provider
                  ?.contactName
              }
            </b>
            {'   '}a{'  '}
            <b>
              {capitalize(
                options.filter((o) => o?._id === value)[0]?.contactName
              )}
            </b>{' '}
            ?
          </p>
        )}

        <br />
        <div className="buttons-center-container">
          <AltButton label={'No'} action={() => handleClose(false)} />{' '}
          {value && (
            <ActionButton action={() => action()} label={'Cambiar gruero'} />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const MessageAVModal = (props) => {
  const { showSMSModal, handleClose, caseInfo, action, service, _case } = props;
  return (
    <Modal
      size="xl"
      centered
      show={showSMSModal?.isOpen}
      onHide={() => handleClose(false)}
    >
      <Modal.Header className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h4>Mensajes de selección de AV</h4>
        <h5>
          Grupo Verde / Mensaje a cliente / Grupo Amarillo / Lista de difusión
          de grueros
        </h5>
        <div className="modal-messages-container">
          <MessageComponent1
            data={caseInfo}
            provider={showSMSModal?.provider}
            service={service}
            _case={_case}
          />
          <MessageComponent2
            data={caseInfo}
            provider={showSMSModal?.provider}
          />
          <MessageComponent3
            data={caseInfo}
            provider={showSMSModal?.provider}
            service={service}
          />
          <MessageComponent4
            data={caseInfo}
            provider={showSMSModal?.provider}
          />
        </div>

        <div className="buttons-center-container">
          <AltButton label={'Cerrar'} action={() => handleClose(false)} />{' '}
          {action && <AltButton label={'Continuar'} action={() => action()} />}
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const MessageComponent1 = (props) => {
  const { data, provider, service, _case } = props;
  return (
    <div className="modal-message-container">
      <p>
        <b>*SERVICIO DE TU/GRUERO*</b>
        <br />-<b>*FECHA*</b>:{' '}
        {moment.utc(data?.createdAt).format('DD/MM/YYYY')}
        <br />-<b>*HORA*</b>: {moment.utc(data?.createdAt).format('LT')}
        <br />-<b>*CLIENTE*</b>:{' '}
        {capitalize(
          data?.particularCustomer?.firstName ||
          data?.membership?.customer?.firstName
        ) +
          ' ' +
          capitalize(
            data?.particularCustomer?.lastName ||
            data?.membership?.customer?.lastName
          )}
        <br />-<b>*TELÉFONO*</b>:{' '}
        {exists(
          data?.particularCustomer?.phonePrefix ||
          data?.membership?.customer?.phonePrefix
        ) +
          '-' +
          exists(
            data?.particularCustomer?.phoneNumber ||
            data?.membership?.customer?.phoneNumber
          )}
        <br />-<b>*PLACA*</b>:{' '}
        {exists(
          data?.particularCustomer?.vehiclePlate ||
          data?.membership?.vehicle?.plate
        )}
        <br />-<b>*VEHICULO*</b>:{' '}
        {capitalize(
          data?.particularCustomer?.vehicleBrand?.brand ||
          data?.membership?.vehicle?.brand
        ) +
          ' ' +
          capitalize(
            data?.particularCustomer?.vehicleModel?.model ||
            data?.membership?.vehicle?.model
          ) +
          ' ' +
          capitalize(
            data?.particularCustomer?.vehicleYear ||
            data?.membership?.vehicle?.year
          ) +
          ' ' +
          capitalize(
            data?.particularCustomer?.vehicleColor ||
            data?.membership?.vehicle?.color
          )}
        <br />-<b>*AVERÍA*</b>: {capitalize(data?.fault?.description)}
        <br />-<b>*COMENTARIO AVERÍA*</b>:{' '}
        {capitalize(
          _case?.selected?.customerFailureDescription
            ? _case?.selected?.customerFailureDescription
            : 'Ninguno'
        )}
        <br />-<b>*ORIGEN*</b>:
        {capitalize(data?.originState?.name) +
          ' ' +
          capitalize(data?.originCity?.name) +
          ' ' +
          capitalize(data?.originAddress)}
        {/* <br />-<b>*CÓDIGO DE PRODUCTO*</b>: XXXXXXXXX */}
        <br />-<b>*OPERADOR*</b>:
        {capitalize(data?.user?.firstName) +
          ' ' +
          capitalize(data?.user?.lastName)}
        <br />-<b>*MOTORIZADO*</b>:{capitalize(provider?.contactName)}
        <br />-<b>*SERVICIO SOLICITADO*</b>:{capitalize(service?.product)}
      </p>

      <CopyToClipboard
        text={smsText.greenGroup(data, provider, service, _case)}
      >
        <button className="copy-sms-text-button">Copiar</button>
      </CopyToClipboard>
    </div>
  );
};
export const MessageComponent2 = (props) => {
  const { data, provider } = props;
  return (
    <div className="modal-message-container">
      <p>
        ¡Estimad@! Ya tienes un asistente vial asignado
        <br />
        Datos del motorizado:
        <br />
        <br />
        <b>*Nombre: {exists(provider?.contactName)} *</b> <br />
        {data?.estimatedTime ? (
          <b>*Tiempo de espera estimado: {data?.estimatedTime} minutos *</b>
        ) : null}
        <br />
        <br />
        Procura mantenerte en el lugar indicado para que nuestro asistente pueda
        ubicar tu posición.
        <br />
        <br />
        <br />
      </p>
      <CopyToClipboard text={smsText.messageToClient(data, provider)}>
        <button className="copy-sms-text-button">Copiar</button>
      </CopyToClipboard>{' '}
    </div>
  );
};

export const MessageComponent3 = (props) => {
  const { data, provider, service } = props;

  return (
    <div className="modal-message-container">
      <p>
        <b>*SERVICIO DE TU/GRUERO*</b>
        <br />-<b>*FECHA*</b>:{' '}
        {moment.utc(data?.createdAt).format('DD/MM/YYYY')}
        <br />-<b>*HORA*</b>: {moment.utc(data?.createdAt).format('LT')}
        <br />
        <b>-*AVERÍA*:</b> {capitalize(data?.fault?.description)}
        <br />-<b>*VEHICULO*</b>:{' '}
        {capitalize(
          data?.particularCustomer?.vehicleBrand?.brand ||
          data?.membership?.vehicle?.brand
        ) +
          ' ' +
          capitalize(
            data?.particularCustomer?.vehicleModel?.model ||
            data?.membership?.vehicle?.model
          ) +
          ' ' +
          capitalize(
            data?.particularCustomer?.vehicleYear ||
            data?.membership?.vehicle?.year
          ) +
          ' ' +
          capitalize(
            data?.particularCustomer?.vehicleColor ||
            data?.membership?.vehicle?.color
          )}{' '}
        <br />
        <b>-*PLACA*:</b>{' '}
        {exists(
          data?.particularCustomer?.vehiclePlate ||
          data?.membership?.vehicle?.plate
        )}
        <br />
        <b>-*TELÉFONO*:</b>
        {exists(
          data?.particularCustomer?.phonePrefix ||
          data?.membership?.customer?.phonePrefix
        ) +
          '-' +
          exists(
            data?.particularCustomer?.phoneNumber ||
            data?.membership?.customer?.phoneNumber
          )}
        <br />
        <b>-*ORIGEN*:</b>{' '}
        {capitalize(data?.originState?.name) +
          ' ' +
          capitalize(data?.originCity?.name) +
          ' ' +
          capitalize(data?.originAddress)}
        <br />
        {data?.destinyState && <b>-*DESTINO*:</b>}
        {capitalize(data?.destinyState?.name) +
          ' ' +
          capitalize(data?.destinyCity?.name) +
          ' ' +
          capitalize(data?.destinyAddress)}
        {/* <br />
        <b>-*CÓDIGO DE SERVICIO*:</b> OFIC15-0024930 */}
        {/* <br />
        <b>-*CÓDIGO DE PRODUCTO*:</b> TGP-OFIC-14310 */}
        {/* <br />
        <b>-*VIGENCIA*:</b> 13/06/2021-13/06/2022
        <br />
        <b>-*DOMICILIO*:</b> Miranda
        <br />
        <b>-*CANT DE SERVICIOS*:</b> 0
        <br />
        <b>-*CLIENTE*:</b> Nuevo
        <br /> */}
        <br />
        <b>-*OPERADOR*: </b>{' '}
        {capitalize(data?.user?.firstName) +
          ' ' +
          capitalize(data?.user?.lastName)}
        <br />-<b>*SERVICIO SOLICITADO*</b>:{capitalize(service?.product)}
        {/* <br />
        <br />
        <b>*SERVICIOS ANTERIORES*:</b> */}
      </p>
      <CopyToClipboard text={smsText.yellowGroup(data, provider, service)}>
        <button className="copy-sms-text-button">Copiar</button>
      </CopyToClipboard>{' '}
    </div>
  );
};

export const MessageComponent4 = (props) => {
  const { data } = props;

  return (
    <div className="modal-message-container">
      <p>
        <b>*SERVICIO DE TU/GRUERO*</b>
        <br />
        <br />-<b>*FECHA*</b>:{' '}
        {moment.utc(data?.createdAt).format('DD/MM/YYYY')}
        <br />-<b>*HORA*</b>: {moment.utc(data?.createdAt).format('LT')}
        <br />-<b>*AVERÍA*</b>: {capitalize(data?.fault?.description)}
        <br />-<b>*VEHICULO*</b>:{' '}
        {capitalize(
          data?.particularCustomer?.vehicleBrand?.brand ||
          data?.membership?.vehicle?.brand
        ) +
          ' ' +
          capitalize(
            data?.particularCustomer?.vehicleModel?.model ||
            data?.membership?.vehicle?.model
          ) +
          ' ' +
          capitalize(
            data?.particularCustomer?.vehicleYear ||
            data?.membership?.vehicle?.year
          ) +
          ' ' +
          capitalize(
            data?.particularCustomer?.vehicleColor ||
            data?.membership?.vehicle?.color
          )}
        <br />
        <b>-*ORIGEN*:</b>{' '}
        {capitalize(data?.originState?.name) +
          ' ' +
          capitalize(data?.originCity?.name) +
          ' ' +
          capitalize(data?.originAddress)}
        <br />
        {data?.destinyState && <b>-*DESTINO*:</b>}
        {capitalize(data?.destinyState?.name) +
          ' ' +
          capitalize(data?.destinyCity?.name) +
          ' ' +
          capitalize(data?.destinyAddress)}
        {/* <b>-*MONTO SIN IVA*:</b> NEGOCIABLE
        <br /> */}
        {/* <b>-*KILOMETRAJE*:</b> 15.46 KM
        <br /> */}
        <br />
        <br />
        <b>*Interesados responder con el precio y el tiempo de espera*</b>
      </p>
      <CopyToClipboard text={smsText.towBroadcastMessage(data)}>
        <button className="copy-sms-text-button">Copiar</button>
      </CopyToClipboard>{' '}
    </div>
  );
};

export const MessageComponent5 = (props) => {
  const { data } = props;
  return (
    <div className="modal-message-container">
      <p>
        <b>*SERVICIO DE TU/GRUERO*</b>
        <br />-<b>*FECHA*</b>:{' '}
        {moment.utc(data?.createdAt).format('DD/MM/YYYY')}
        <br />-<b>*HORA*</b>: {moment.utc(data?.createdAt).format('LT')}
        <br />-<b>*CLIENTE*</b>:{' '}
        {capitalize(
          data?.particularCustomer?.firstName ||
          data?.membership?.customer?.firstName
        ) +
          ' ' +
          capitalize(
            data?.particularCustomer?.lastName ||
            data?.membership?.customer?.lastName
          )}
        <br />-<b>*TELÉFONO*</b>:{' '}
        {exists(
          data?.particularCustomer?.phonePrefix ||
          data?.membership?.customer?.phonePrefix
        ) +
          '-' +
          exists(
            data?.particularCustomer?.phoneNumber ||
            data?.membership?.customer?.phoneNumber
          )}
        <br />-<b>*PLACA*</b>:{' '}
        {exists(
          data?.particularCustomer?.vehiclePlate ||
          data?.membership?.vehicle?.plate
        )}
        <br />-<b>*VEHICULO*</b>:{' '}
        {capitalize(
          data?.particularCustomer?.vehicleBrand?.brand ||
          data?.membership?.vehicle?.brand
        ) +
          ' ' +
          capitalize(
            data?.particularCustomer?.vehicleModel?.model ||
            data?.membership?.vehicle?.model
          ) +
          ' ' +
          capitalize(
            data?.particularCustomer?.vehicleYear ||
            data?.membership?.vehicle?.year
          ) +
          ' ' +
          capitalize(
            data?.particularCustomer?.vehicleColor ||
            data?.membership?.vehicle?.color
          )}
        <br />-<b>*AVERÍA*</b>: {capitalize(data?.fault?.description)}
        <br />-<b>*ORIGEN*</b>:
        {capitalize(data?.originState?.name) +
          ' ' +
          capitalize(data?.originCity?.name) +
          ' ' +
          capitalize(data?.originAddress)}
        {data?.destinyState?.name && (
          <>
            <br /> - <b>*DESTINO*</b>
          </>
        )}
        :
        {capitalize(data?.destinyState?.name) +
          ' ' +
          capitalize(data?.destinyCity?.name) +
          ' ' +
          capitalize(data?.destinyAddress)}
        {data?.caseKm && (
          <>
            <br /> - <b>*DISTANCIA*: </b> {exists(data?.caseKm)}Km
          </>
        )}
        {/* <br />-<b>*CÓDIGO DE PRODUCTO*</b>: XXXXXXXXX */}
        <br />-<b>*OPERADOR*</b>:
        {capitalize(data?.user?.firstName) +
          ' ' +
          capitalize(data?.user?.lastName)}
        <br />
        <br />
        <br />-<b>*Interesados responder con el tiempo de espera*</b>
      </p>

      <CopyToClipboard text={smsText.towMesage(data)}>
        <button className="copy-sms-text-button">Copiar</button>
      </CopyToClipboard>
    </div>
  );
};

export const CreateSalesUserModal = (props) => {
  const {
    reset,
    onChange,
    modal,
    handleModal,
    register,
    errors,
    action,
    options,
    watch,
  } = props;

  const close = () => {
    handleModal();
    reset();
  };

  return (
    <Modal size="lg" centered show={modal?.isOpen} onHide={() => close()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>Nuevo Usuario</h5>
        <CreateSalesUserForm
          register={register}
          errors={errors}
          action={action}
          cancel={() => close()}
          options={options}
          onChange={onChange}
          watch={watch}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const ChangeCustomerModal = (props) => {
  const {
    reset,
    onChange,
    modal,
    handleModal,
    register,
    errors,
    action,
    options,
    watch,
  } = props;

  const close = () => {
    handleModal();
    reset();
  };

  return (
    <Modal size="lg" centered show={modal?.isOpen} onHide={() => close()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>Cambio de Titular de la Afiliación</h5>
        <ChangeUserForm
          register={register}
          errors={errors}
          action={action}
          cancel={() => close()}
          options={options}
          onChange={onChange}
          watch={watch}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const ClientChangeHistoryModal = (props) => {
  const {
    reset,
    modal,
    handleModal,
    header,
    data
  } = props;

  const close = () => {
    handleModal();
    reset();
  };

  if (!data) {
    return null
  }

  return (
    <Modal size="xl" centered show={modal?.isOpen} onHide={() => close()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>Cambio de cliente</h5>

        <table className="table">
          <tr>
            {header.map((h) => (
              <th key={h?.value}>
                {h?.name}
              </th>
            ))}
          </tr>
          {data.map((d) => (
            <tr key={d?._id}>
              <td>{moment.utc(data?.changeDate).format('DD/MM/YYYY')}</td>
              <td>{capitalize(d?.user)}</td>
              <td>{d?.firstName + " " + d?.lastName}</td>
              <td>{d?.identificationType + "-" + d?.identification}</td>
              <td>{d?.email}</td>
              <td>{d?.phonePrefix + "-" + d?.phoneNumber}</td>
            </tr>
          ))}
        </table>
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const VehicleChangeHistoryModal = (props) => {
  const {
    reset,
    modal,
    handleModal,
    header,
    data
  } = props;

  const close = () => {
    handleModal();
    reset();
  };

  if (!data) {
    return null
  }

  return (
    <Modal size="xl" centered show={modal?.isOpen} onHide={() => close()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>Cambio de Vehiculo</h5>

        <table className="table">
          <tr>
            {header.map((h) => (
              <th key={h?.value}>
                {h?.name}
              </th>
            ))}
          </tr>
          {data.map((d) => (
            <tr key={d?._id}>
              <td>{moment.utc(data?.changeDate).format('DD/MM/YYYY')}</td>
              <td>{capitalize(d?.user)}</td>
              <td>{d?.plate}</td>
              <td>{capitalize(d?.brand)}</td>
              <td>{capitalize(d?.model)}</td>
              <td>{capitalize(d?.bodySerial)}</td>
              <td>{capitalize(d?.color)}</td>
            </tr>
          ))}
        </table>
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};



export const ChangeVehicleModal = (props) => {
  const {
    reset,
    onChange,
    modal,
    handleModal,
    register,
    errors,
    action,
    options,
    watch,
    validateTruck
  } = props;

  const close = () => {
    handleModal();
    reset();
  };

  return (
    <Modal size="xl" centered show={modal?.isOpen} onHide={() => close()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>Cambio de Titular de Vehiculo</h5>
        <ChangeVehicleForm
          register={register}
          errors={errors}
          action={action}
          validateTruck={validateTruck}
          cancel={() => close()}
          options={options}
          onChange={onChange}
          watch={watch}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};


export const MessageComponent6 = (props) => {
  const { data } = props;
  return (
    <div className="modal-message-container">
      <p>
        <b>*SERVICIO DE TU/GRUERO*</b>
        <br />-<b>*FECHA*</b>:{' '}
        {moment.utc(data?.createdAt).format('DD/MM/YYYY')}
        <br />-<b>*HORA*</b>: {moment.utc(data?.createdAt).format('LT')}
        <br />-<b>*CLIENTE*</b>:{' '}
        {capitalize(
          data?.particularCustomer?.firstName ||
          data?.membership?.customer?.firstName
        ) +
          ' ' +
          capitalize(
            data?.particularCustomer?.lastName ||
            data?.membership?.customer?.lastName
          )}
        <br />-<b>*TELÉFONO*</b>:{' '}
        {exists(
          data?.particularCustomer?.phonePrefix ||
          data?.membership?.customer?.phonePrefix
        ) +
          '-' +
          exists(
            data?.particularCustomer?.phoneNumber ||
            data?.membership?.customer?.phoneNumber
          )}
        <br />-<b>*PLACA*</b>:{' '}
        {exists(
          data?.particularCustomer?.vehiclePlate ||
          data?.membership?.vehicle?.plate
        )}
        <br />-<b>*VEHICULO*</b>:{' '}
        {capitalize(
          data?.particularCustomer?.vehicleBrand?.brand ||
          data?.membership?.vehicle?.brand
        ) +
          ' ' +
          capitalize(
            data?.particularCustomer?.vehicleModel?.model ||
            data?.membership?.vehicle?.model
          ) +
          ' ' +
          capitalize(
            data?.particularCustomer?.vehicleYear ||
            data?.membership?.vehicle?.year
          ) +
          ' ' +
          capitalize(
            data?.particularCustomer?.vehicleColor ||
            data?.membership?.vehicle?.color
          )}
        <br />-<b>*AVERÍA*</b>: {capitalize(data?.fault?.description)}
        <br />-<b>*ORIGEN*</b>:
        {capitalize(data?.originState?.name) +
          ' ' +
          capitalize(data?.originCity?.name) +
          ' ' +
          capitalize(data?.originAddress)}
        {data?.destinyState?.name && (
          <>
            <br /> - <b>*DESTINO*</b>
          </>
        )}
        :
        {capitalize(data?.destinyState?.name) +
          ' ' +
          capitalize(data?.destinyCity?.name) +
          ' ' +
          capitalize(data?.destinyAddress)}
        {data?.membership?.beginDate && data?.membership?.endDate && (
          <>
            <br /> - <b>*DISTANCIA*: </b> {exists(data?.caseKm)}Km
          </>
        )}
        {data?.membership?.customer?.homeCity && (
          <>
            <br /> - <b>*DOMICILIO*: </b>
            {capitalize(data?.membership?.customer?.homeState) + ' '}
            {capitalize(data?.membership?.customer?.homeCity) + ' '}
            {capitalize(data?.membership?.customer?.homeMunicipality) + ' '}
            {capitalize(data?.membership?.customer?.homeAddress)}
          </>
        )}
        {data?.membership?.beginDate && (
          <>
            <br /> - <b>*VIGENCIA*: </b>{' '}
            {exists(
              moment.utc(data?.membership?.beginDate).format('DD/MM/YYYY')
            )}
            -
            {exists(moment.utc(data?.membership?.endDate).format('DD/MM/YYYY'))}
          </>
        )}
        <br />-<b>*OPERADOR*</b>:
        {capitalize(data?.user?.firstName) +
          ' ' +
          capitalize(data?.user?.lastName)}
        <br />
      </p>

      <CopyToClipboard text={smsText.towServiceMessage(data)}>
        <button className="copy-sms-text-button">Copiar</button>
      </CopyToClipboard>
    </div>
  );
};

export const MessageTowModal = (props) => {
  const { showSMSModal, handleClose, caseInfo, action } = props;
  return (
    <Modal
      size="xl"
      centered
      show={showSMSModal?.isOpen}
      onHide={() => handleClose(false)}
    >
      <Modal.Header className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h4>Mensajes de selección de grueros</h4>
        <h5>
          Grupo Verde / Mensaje a cliente / Grupo Amarillo / Lista de difusión
          de grueros
        </h5>
        <div className="modal-messages-container">
          <MessageComponent5
            data={caseInfo}
            provider={showSMSModal?.provider}
          />
          <MessageComponent6
            data={caseInfo}
            provider={showSMSModal?.provider}
          />
        </div>

        <div className="buttons-center-container">
          <AltButton label={'Cerrar'} action={() => handleClose(false)} />{' '}
          {action && <AltButton label={'Continuar'} action={() => action()} />}
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const ProviderDetailModal = (props) => {
  const { show, handleClose, action } = props;

  return (
    <Modal size="lg" centered show={show} onHide={() => handleClose(false)}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h3>Detalles del Proveedor</h3>
        <br />
        <table className="providers-detail-table">
          <tr>
            <td>
              <b>-Telefonos de Contacto:</b>
            </td>
            <td>02123191250</td>
          </tr>
          <tr>
            <td>
              <b>-Turno:</b>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <b>-Horario de atencion:</b>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <b>-Whee life (de 0 a 3500 Kg):</b>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <b>-Plataforma (de 0 a 5 Tonelada):</b>
            </td>
            <td>1</td>
          </tr>
          <tr>
            <td>
              <b>-Gancho (de 5 Hasta 8 Toneladas):</b>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <b>-Extra Pesado (de 8 hasta 20 Toneladas):</b>
            </td>
            <td></td>
          </tr>
        </table>
        <br />
        <div className="buttons-center-container">
          <AltButton action={() => handleClose(false)} label={'Cancelar'} />
          <ActionButton action={action} label={'Seleccionar'} />
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const ApprovalModal = (props) => {
  const { show, handleClose, action, t, register, errors } = props;

  return (
    <Modal size="lg" centered show={show} onHide={() => handleClose(false)}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h3>Se necesita aprobación para continuar el proceso</h3>
        <ApprovalPaymentForm
          t={t}
          register={register}
          errors={errors}
          action={action}
          cancel={() => handleClose(false)}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const ResendDocumentModal = (props) => {
  const { show, handleClose, action } = props;

  return (
    <Modal size="lg" centered show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h3>Esta seguro que desea reenviar los documentos al cliente</h3>
        <br />
        <br />
        <br />
      </Modal.Body>
      <div className="buttons-center-container">
        <AltButton action={handleClose} label={'Cancel'} />
        <ActionButton action={action} label={'Si, reenviar'} />
      </div>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const NewIncidenceModal = (props) => {
  const { show, handleClose, particular, afiliated } = props;

  return (
    <Modal size="lg" centered show={show} onHide={() => handleClose(false)}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h3 style={{ marginBottom: '24px' }}>Nueva Incidencia</h3>
        <h6 style={{ margin: '24px' }}>
          Seleccione el tipo de incidencia a crear
        </h6>
      </Modal.Body>
      <div className="buttons-center-container">
        <AltButton action={particular} label={'Cliente Particular'} />
        <ActionButton action={afiliated} label={'Cliente Afiliado'} />
      </div>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const MapModal = (props) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
  });

  const {
    show,
    handleClose,
    origin,
    setOrigin,
    destiny,
    setDestiny,
    route,
    setRoute,
  } = props;

  if (!isLoaded) {
    return null;
  }

  return (
    <Modal size="xl" centered show={show} onHide={() => handleClose(false)}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <div className="google-maps-container">
          <Map
            origin={origin}
            setOrigin={setOrigin}
            destiny={destiny}
            setDestiny={setDestiny}
            handleClose={handleClose}
            route={route}
            setRoute={setRoute}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const CreateAVModal = (props) => {
  const {
    show,
    handleClose,
    register,
    createAVService,
    errors,
    options,
    watch,
    onChange,
    fileUploaded,
    setFileUploaded,
    bsDisabled,
  } = props;

  return (
    <Modal size="xl" centered show={show} onHide={() => handleClose(false)}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <div className="google-maps-container"></div>
        <CreateAVForm
          register={register}
          cancel={() => handleClose(false)}
          action={createAVService}
          errors={errors}
          options={options}
          watch={watch}
          onChange={onChange}
          fileUploaded={fileUploaded}
          setFileUploaded={setFileUploaded}
          bsDisabled={bsDisabled}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const CreateTowServiceModal = (props) => {
  const {
    show,
    handleClose,
    register,
    payTowService,
    errors,
    options,
    watch,
    onChange,
    fileUploaded,
    setFileUploaded,
    selectedProvider,
    bsDisabled,
  } = props;

  return (
    <Modal size="xl" centered show={show} onHide={() => handleClose(false)}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <div className="google-maps-container"></div>
        <CreateTowForm
          register={register}
          cancel={() => handleClose(false)}
          action={payTowService}
          errors={errors}
          options={options}
          watch={watch}
          onChange={onChange}
          fileUploaded={fileUploaded}
          setFileUploaded={setFileUploaded}
          selectedProvider={selectedProvider}
          bsDisabled={bsDisabled}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const UploadImageModal = (props) => {
  const { handleClose, imageModal } = props;

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        // Do whatever you want with the file contents
      };
      reader.readAsArrayBuffer(file);

      if (
        !['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(
          file.type
        )
      ) {
        notify('Agregar un formato valido de imagen', 'error');
        return null;
      }
      let maxWeight = 5;
      if (file.size * 0.000001 > maxWeight) {
        notify(`La imagen no debe superar los ${maxWeight} MB`, 'error');
      } else {
        setFileUploaded(file);
      }
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const [fileUploaded, setFileUploaded] = useState('');
  const [canPost, setCanPost] = useState(true);

  const appendFile = (file) => {
    const formData = new FormData();
    formData.append('evidence', file);
    return formData;
  };

  const uploadAVImage = async () => {
    let fileToPost = appendFile(fileUploaded);

    if (canPost) {
      setCanPost(false);
      await servicesService
        .uploadAVImage(fileToPost, imageModal?.selected)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Imagen cargada con exito`, 'success');
            handleClose();
            setFileUploaded('');
            setCanPost(true);
          } else {
            notify(`${response.message}`, 'error');
            setCanPost(true);
          }
        });
    }
  };

  const close = () => {
    setFileUploaded('');
    handleClose();
  };

  return (
    <Modal size="xl" centered show={imageModal?.isOpen} onHide={() => close()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h1 style={{ marginBottom: '24px' }}>Cargar imágenes de servicio AV</h1>
        <div className="dropzone-container">
          {imageModal?.label && <h6>{imageModal?.label}</h6>}
          {fileUploaded ? (
            <FileComponent
              file={fileUploaded}
              remove={() => setFileUploaded('')}
            />
          ) : (
            <Dropzone
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              message={imageModal?.message}
            />
          )}
        </div>
      </Modal.Body>
      <div className="buttons-center-container">
        <AltButton label={'Cancelar'} action={() => close()} />
        {fileUploaded && (
          <ActionButton
            label={'Enviar Imagen'}
            action={() => uploadAVImage()}
          />
        )}
      </div>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const ServiceImageModal = (props) => {
  const { handleClose, imageModal } = props;

  const [fileUploaded, setFileUploaded] = useState([]);
  const [showImages, setShowImages] = useState([true, true, true]);
  const [canPost, setCanPost] = useState(true);

  const appendFile = (file) => {
    const formData = new FormData();
    for (let i = 0; i < file.length; i++) {
      if (file[i]?.name) {
        formData.append(images[i]?.name, file[i]);
      }
    }
    return formData;
  };

  const uploadImages = async () => {
    let fileToPost = appendFile(fileUploaded);

    if (canPost) {
      setCanPost(false);
      await servicesService
        .uploadTowImage(fileToPost, imageModal?.selected)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Imagenes cargadas con exito`, 'success');
            handleClose();
            setFileUploaded([]);
            setCanPost(true);
            window.location.reload();
          } else {
            notify(`${response.message}`, 'error');
            setCanPost(true);
          }
        });
    }
  };

  const close = () => {
    setFileUploaded([]);
    handleClose();
    setShowImages([true, true, true]);
  };

  const images = [
    {
      name: 'towAtOrigin',
      label: 'Gruero en origen',
      message: '(PNG, JPG, JEPG, máx. 3MB)',
      uploaded: imageModal?.uploaded?.towAtOrigin,
    },
    {
      name: 'vehicleOnTow',
      label: 'Vehículo en grúa',
      message: '(PNG, JPG, JEPG, máx. 3MB)',
      uploaded: imageModal?.uploaded?.vehicleOnTow,
    },
    {
      name: 'vehicleOnRoad',
      label: 'Vehículo en destino',
      message: '(PNG, JPG, JEPG, máx. 3MB)',
      uploaded: imageModal?.uploaded?.vehicleOnRoad,
    },
  ];

  return (
    <Modal size="xl" centered show={imageModal?.isOpen} onHide={() => close()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h1 style={{ marginBottom: '24px' }}>
          Cargar imágenes de servicio de grúa
        </h1>
        {images.map((img, index) => {
          return (
            <NewDropzone
              key={img?.name}
              fileUploaded={fileUploaded}
              setFileUploaded={setFileUploaded}
              img={img}
              index={index}
              setShowImages={setShowImages}
              showImages={showImages}
            />
          );
        })}
      </Modal.Body>
      <div className="buttons-center-container">
        <AltButton label={'Cancelar'} action={() => close()} />
        {fileUploaded.length > 0 && (
          <ActionButton label={'Enviar Imagen'} action={() => uploadImages()} />
        )}
      </div>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const ChangeCaseStageModal = (props) => {
  const { showChangeStatusModal, handleClose, updateCaseStage } = props;

  return (
    <Modal
      size="lg"
      centered
      show={showChangeStatusModal?.isOpen}
      onHide={() => handleClose()}
    >
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h3 style={{ marginBottom: '24px' }}>
          Mover caso {showChangeStatusModal?.selected?.caseId} a{' '}
          {showChangeStatusModal?.nextStatus}{' '}
        </h3>
        <h6 className="change-case-stage-modal" style={{ margin: '24px' }}>
          ¿Está seguro que desea mover el caso:{' '}
          {showChangeStatusModal?.selected?.caseId} de <br />
          <b>{showChangeStatusModal?.selected?.lastStage?.name}</b> a{' '}
          <b>{showChangeStatusModal?.nextStatus}</b>?
        </h6>
      </Modal.Body>
      <div className="buttons-center-container">
        <AltButton label={'No'} action={() => handleClose()} />
        <ActionButton
          label={'Si, cambiar de estatus'}
          action={() => updateCaseStage()}
        />
      </div>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const SalesOrderImageModal = (props) => {
  const { closeImageModal, imageModal, refresh } = props;

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        // Do whatever you want with the file contents
      };
      reader.readAsArrayBuffer(file);

      if (
        !['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(
          file.type
        )
      ) {
        notify('Agregar un formato valido de imagen', 'error');
        return null;
      }
      let maxWeight = 5;
      if (file.size * 0.000001 > maxWeight) {
        notify(`La imagen no debe superar los ${maxWeight} MB`, 'error');
      } else {
        setFileUploaded(file);
      }
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const [fileUploaded, setFileUploaded] = useState('');
  const [canPost, setCanPost] = useState(true);

  const appendFile = (file) => {
    const formData = new FormData();
    formData.append(`${imageModal?.tag}`, file);
    return formData;
  };

  const uploadAVImage = async () => {
    let fileToPost = appendFile(fileUploaded);

    if (canPost) {
      setCanPost(false);
      await membershipService
        .updateImages(fileToPost, imageModal?.selected)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Imagen cargada con exito`, 'success');
            refresh();
            closeImageModal();
            setFileUploaded('');
            setCanPost(true);
          } else {
            notify(`${response.message}`, 'error');
            setCanPost(true);
          }
        });
    }
  };

  const close = () => {
    setFileUploaded('');
    closeImageModal();
  };

  return (
    <Modal size="xl" centered show={imageModal?.isOpen} onHide={() => close()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h1 style={{ marginBottom: '24px' }}>
          Cargar {capitalize(imageModal?.label)}{' '}
        </h1>
        <div className="buttons-container">
          {/* {imageModal?.label && <h6>{imageModal?.label}</h6>} */}
          {fileUploaded ? (
            <FileComponent
              file={fileUploaded}
              remove={() => setFileUploaded('')}
            />
          ) : (
            <Dropzone
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              message={imageModal?.message}
            />
          )}
        </div>
      </Modal.Body>
      <div className="buttons-center-container">
        <AltButton label={'Cancelar'} action={() => close()} />
        {fileUploaded && (
          <ActionButton
            label={'Enviar Imagen'}
            action={() => uploadAVImage()}
          />
        )}
      </div>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const CreateFleetModal = (props) => {
  const { modal, handleModal, getFleets } = props;

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schemas.CreateFleetSchema(t)),
  });
  const [isLoading, setIsloading] = useState(false);
  // Function that handles the logout request
  const onSubmit = handleSubmit(async (data) => {
    if (!isLoading) {
      setIsloading(true);
      fleetService
        .createFleet(data)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Flota creada con éxito`, 'success');
            setIsloading(false);
            handleModal();
            close();
            getFleets();
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const close = () => {
    handleModal();
    reset();
  };

  return (
    <Modal size="lg" centered show={modal?.isOpen} onHide={() => close()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>Datos de flota</h5>
        <CreateFleetForm
          t={t}
          register={register}
          errors={errors}
          action={onSubmit}
          cancel={() => close()}
          cancelOptions={modal?.cancelOptions}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const EditFleetModal = (props) => {
  const { modal, handleModal, getFleets } = props;

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schemas.CreateFleetSchema(t)),
  });
  const [isLoading, setIsloading] = useState(false);
  // Function that handles the logout request
  const onSubmit = handleSubmit(async (data) => {
    if (!isLoading) {
      setIsloading(true);
      fleetService
        .editFleet(data, modal?.selected?._id)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Flota editada con éxito`, 'success');
            setIsloading(false);
            handleModal();
            close();
            getFleets();
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch(() => {
          setIsloading(false);
        });
    }
  });

  const close = () => {
    handleModal();
    reset();
  };

  const preloadForm = () => {
    setValue('name', modal?.selected?.name);
    setValue('code', modal?.selected?.code);
    setValue('status', modal?.selected?.isActive);
  };

  useEffect(() => {
    preloadForm();
  }, [modal?.isOpen]);

  return (
    <Modal size="lg" centered show={modal?.isOpen} onHide={() => close()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>Editar flota</h5>
        <CreateFleetForm
          t={t}
          register={register}
          errors={errors}
          action={onSubmit}
          cancel={() => close()}
          cancelOptions={modal?.cancelOptions}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const CancelFleetLoadModal = (props) => {
  const { modal, handleModal, getFleets } = props;

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schemas.cancelFleetLoad(t)),
  });
  const [isLoading, setIsloading] = useState(false);
  // Function that handles the logout request
  const onSubmit = handleSubmit(async (data) => {
    if (!isLoading) {
      setIsloading(true);
      fleetLoadService
        .cancelFleetsLoads(data, modal?.selected?._id)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Carga de flotas anulada`, 'success');
            setIsloading(false);
            handleModal();
            close();
            getFleets();
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const close = () => {
    handleModal();
    reset();
  };

  return (
    <Modal size="lg" centered show={modal?.isOpen} onHide={() => close()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>Anular carga de flota {modal?.selected?.fleetLoadId}</h5>
        <CancelFleetForm
          t={t}
          register={register}
          errors={errors}
          action={onSubmit}
          cancel={() => close()}
          cancelOptions={cancelFleatLoadOptions}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const RenewFleetLoadModal = (props) => {
  const {
    close,
    action,
    modal,
    options,
    register,
    control,
    watch,
    onChange,
    selectedPlan,
    errors = { errors },
  } = props;

  return (
    <Modal size="lg" centered show={modal?.isOpen} onHide={() => close()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>Datos de la Carga</h5>
        <h6>{modal?.selected?.length} Registros seleccionados</h6>
        <RenewFleetLoadForm
          register={register}
          errors={errors}
          action={action}
          cancel={() => close()}
          control={control}
          options={options}
          watch={watch}
          onChange={onChange}
          selectedPlan={selectedPlan}
          cancelOptions={cancelFleatLoadOptions}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const RenewModal = (props) => {
  const {
    close,
    action,
    modal,
    options,
    register,
    control,
    watch,
    onChange,
    priceInfo,
    errors = { errors },
  } = props;



  return (
    <Modal size="lg" centered show={modal?.isOpen} onHide={() => close()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>Información de la Renovación</h5>
        <RenewInforForm
          register={register}
          errors={errors}
          action={action}
          cancel={() => close()}
          control={control}
          onChange={onChange}
          options={options}
          watch={watch}
          priceInfo={priceInfo}
          cancelOptions={cancelFleatLoadOptions}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};



export const DeleteFleetLoadModal = (props) => {
  const { modal, handleModal, getFleets, deleteFleetLoad } = props;

  const { t } = useTranslation();
  const {
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(schemas.cancelFleetLoad(t)),
  });
  const [isLoading, setIsloading] = useState(false);
  // Function that handles the logout request
  const onSubmit = handleSubmit(async (data) => {
    if (!isLoading) {
      setIsloading(true);
      fleetLoadService
        .cancelFleetsLoads(data, modal?.selected?._id)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Carga de flotas anulada`, 'success');
            setIsloading(false);
            handleModal();
            close();
            getFleets();
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const close = () => {
    handleModal();
    reset();
  };

  return (
    <Modal size="lg" centered show={modal?.isOpen} onHide={() => close()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5 style={{ marginBottom: '24px' }}>
          Eliminar carga de flota {modal?.selected?.fleetLoadId}
        </h5>

        <h6 style={{ textAlign: 'center' }}>
          ¿Estas seguro de eliminar la carga de la flota?
          <br />
          Esta acción es irreversible, no podrás recuperar la información actual
          <br />
          <br />
        </h6>
        <div className="buttons-center-container">
          <AltButton action={close} label={'No'} />
          <ActionButton action={deleteFleetLoad} label={'Si'} />
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const ActivateFleetLoadModal = (props) => {
  const { modal, handleModal, getFleets, activateFleetLoad } = props;

  const { t } = useTranslation();
  const {
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(schemas.cancelFleetLoad(t)),
  });
  const [isLoading, setIsloading] = useState(false);
  // Function that handles the logout request
  const onSubmit = handleSubmit(async (data) => {
    if (!isLoading) {
      setIsloading(true);
      fleetLoadService
        .cancelFleetsLoads(data, modal?.selected?._id)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Carga de flotas anulada`, 'success');
            setIsloading(false);
            handleModal();
            close();
            getFleets();
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const close = () => {
    handleModal();
    reset();
  };

  return (
    <Modal size="lg" centered show={modal?.isOpen} onHide={() => close()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5 style={{ marginBottom: '24px' }}>
          Reactivar carga de flota {modal?.selected?.fleetLoadId}
        </h5>

        <h6 style={{ textAlign: 'center' }}>
          ¿Estas seguro de reactivar la carga de la flota?
          <br />
        </h6>
        <div className="buttons-center-container">
          <AltButton action={close} label={'No'} />
          <ActionButton action={activateFleetLoad} label={'Si'} />
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const FleetLoadStatusModal = (props) => {
  const { modal, handleModal, data } = props;

  const close = () => {
    handleModal();
  };

  return (
    <Modal size="lg" centered show={modal?.isOpen} onHide={() => close()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5 style={{ marginBottom: '24px' }}>Estatus de la carga</h5>

        <h6>{data?.status}</h6>
        <div style={{ width: '450px' }} className="row">
          <div className="col-12">
            {data?.createdAt && (
              <TextComponent
                label={'Fecha de creación'}
                text={moment.utc(data?.createdAt).format('DD/MM/YYYY')}
              />
            )}
            {/* <TextComponent
              label={'Responsable de creación'}
              text={'XXXXXXXXXXXX'}
            /> */}

            {data?.activatedAt && (
              <TextComponent
                label={'Fecha de activación'}
                text={moment.utc(data?.activatedAt).format('DD/MM/YYYY')}
              />
            )}
            {data?.activatedBy?.firstName && (
              <TextComponent
                label={'Responsable de activación'}
                text={
                  data?.activatedBy?.firstName +
                  '  ' +
                  data?.activatedBy?.lastName
                }
              />
            )}
            {data?.canceledAt && (
              <TextComponent
                label={'Fecha de anulación'}
                text={moment.utc(data?.canceledAt).format('DD/MM/YYYY')}
              />
            )}
            {data?.canceledBy?.firstName && (
              <TextComponent
                label={'Responsable de anulación'}
                text={
                  data?.canceledBy?.firstName +
                  '  ' +
                  data?.canceledBy?.lastName
                }
              />
            )}

            {data?.deletedAt && (
              <TextComponent
                label={'Fecha de eliminación'}
                text={moment.utc(data?.deletedAt).format('DD/MM/YYYY')}
              />
            )}

            {data?.deletedBy?.firstName && (
              <TextComponent
                label={'Responsable de eliminación'}
                text={
                  data?.deletedBy?.firstName + '  ' + data?.deletedBy?.lastName
                }
              />
            )}
          </div>
        </div>

        <div className="buttons-center-container">
          <ActionButton action={close} label={'Aceptar'} />
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const FleetPaymentPlanModal = (props) => {
  const { modal, handleModal, data, handleReportPaymentPlanModal } = props;

  const close = () => {
    handleModal();
  };

  return (
    <Modal size="lg" centered show={modal?.isOpen} onHide={() => close()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5 style={{ marginBottom: '24px' }}>Plan de Financiamiento</h5>

        <h6>
          {modal?.selected?.financingTerm
            ? modal?.selected?.financingTerm
            : modal?.selected?.paymentMethod}
        </h6>

        <PaymentPlanTable
          header={paymentPlanTableHeader}
          data={data?.paymentDues}
          handleReportPaymentPlanModal={handleReportPaymentPlanModal}
        />

        <div style={{ width: '500px' }} className="buttons-center-container">
          <h6 style={{ textAlign: 'center', marginRight: '60px' }}>
            Cuentas Pagadas
            <br />
            {data?.paidDues?.toFixed(2)} USD
          </h6>
          <h6 style={{ textAlign: 'center' }}>
            Por cobrar <br />
            {data?.pendingDues?.toFixed(2)} USD
          </h6>
        </div>

        <div className="buttons-center-container">
          <ActionButton action={close} label={'Aceptar'} />
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const ReportFleetPaymentPlanModal = (props) => {
  const {
    modal,
    handleModal,
    options,
    register,
    errors,
    control,
    fileUploaded,
    setFileUploaded,
    getRootProps,
    getInputProps,
    action,
  } = props;

  const close = () => {
    handleModal();
  };

  return (
    <Modal size="lg" centered show={modal?.isOpen} onHide={() => close()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5 style={{ marginBottom: '24px' }}>Registrar pago</h5>

        <FleetLoadPaymentForm
          register={register}
          errors={errors}
          control={control}
          options={options}
          fileUploaded={fileUploaded}
          setFileUploaded={setFileUploaded}
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          action={action}
        />

        <div className="buttons-center-container">
          <AltButton action={close} label={'Cancelar'} />
          <ActionButton action={action} label={'Pagar'} />
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const EditSalesUserModal = (props) => {
  const {
    reset,
    onChange,
    modal,
    handleModal,
    register,
    errors,
    action,
    options,
  } = props;

  const close = () => {
    handleModal();
    reset();
  };

  return (
    <Modal size="lg" centered show={modal?.isOpen} onHide={() => close()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>Nuevo Usuario</h5>
        <EditSalesUserForm
          register={register}
          errors={errors}
          action={action}
          cancel={() => close()}
          options={options}
          onChange={onChange}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const ChangeRateModal = (props) => {
  const { modal, handleModal, rate, createRate } = props;

  const post = () => {
    createRate();
    close();
  };

  const close = () => {
    handleModal();
  };

  return (
    <Modal size="lg" centered show={modal} onHide={() => close()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>Actualizar Tasa</h5>
        <br />
        <p>
          Esta seguro que desea actualizar la tasa a <b>{rate} BsS </b> por
          dollar ?
        </p>
      </Modal.Body>
      <div className="buttons-center-container">
        <AltButton action={close} label={'Cancelar'} />
        <ActionButton action={post} label={'Si, Actualizar'} />
      </div>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const MembershipHistoryModal = (props) => {
  const { modal, handleModal, data, } = props;


  const close = () => {
    handleModal();
  };

  return (
    <Modal size="lg" centered show={modal} onHide={() => close()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>Historial de Servicios</h5>
        <br />
        <MembershipHistoryTable
          header={serviceHistoryTableheader}
          data={data}
        />
      </Modal.Body>
      {/* <div classNahandleHistoryModal */}
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const PaymentModal = (props) => {
  const {
    show,
    handleClose,
    register,
    payRewenMembership,
    errors,
    options,
    watch,
    onChange,
    fileUploaded,
    setFileUploaded,
    selectedProvider,
    bsDisabled,
    setValue,
  } = props;

  return (
    <Modal size="xl" centered show={show} onHide={() => handleClose(false)}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <div className="google-maps-container"></div>
        <NewPaymentModal
          register={register}
          cancel={() => handleClose(false)}
          action={payRewenMembership}
          errors={errors}
          options={options}
          setValue={setValue}
          watch={watch}
          onChange={onChange}
          fileUploaded={fileUploaded}
          setFileUploaded={setFileUploaded}
          selectedProvider={selectedProvider}
          bsDisabled={bsDisabled}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const EditPaymentModal = (props) => {
  const {
    modal,
    handleClose,
    register,
    action,
    errors,
    options,
    onChange,
    setValue,
    control
  } = props;

  return (
    <Modal size="xl" centered show={modal?.isOpen} onHide={() => handleClose(false)}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <div className="google-maps-container"></div>
        <EditPaymentForm
          register={register}
          cancel={() => handleClose(false)}
          action={action}
          errors={errors}
          options={options}
          setValue={setValue}
          onChange={onChange}
          control={control}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const PendingPaymentModal = (props) => {
  const {
    show,
    handleClose,
    register,
    action,
    errors,
    options,
    watch,
    onChange,
    fileUploaded,
    setFileUploaded,
    selectedProvider,
    bsDisabled,
  } = props;

  return (
    <Modal size="xl" centered show={show} onHide={() => handleClose(false)}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <div className="google-maps-container"></div>
        <PendingPaymentForm
          register={register}
          cancel={() => handleClose(false)}
          action={action}
          errors={errors}
          options={options}
          watch={watch}
          onChange={onChange}
          fileUploaded={fileUploaded}
          setFileUploaded={setFileUploaded}
          selectedProvider={selectedProvider}
          bsDisabled={bsDisabled}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const UpgradeMembershipModal = (props) => {
  const {
    show,
    handleClose,
    register,
    action,
    errors,
    options,
    watch,
    onChange,
    fileUploaded,
    setFileUploaded,
    selectedProvider,
    bsDisabled,
  } = props;

  return (
    <Modal size="xl" centered show={show} onHide={() => handleClose(false)}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <div className="google-maps-container"></div>
        <UpgradeMembershipForm
          register={register}
          cancel={() => handleClose(false)}
          action={action}
          errors={errors}
          options={options}
          watch={watch}
          onChange={onChange}
          fileUploaded={fileUploaded}
          setFileUploaded={setFileUploaded}
          selectedProvider={selectedProvider}
          bsDisabled={bsDisabled}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const CreateDiscountCodeModal = (props) => {
  const { modal, handleModal, options, getCodes } = props;

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(schemas.CreateDiscountCodeSchema(t)),
  });
  const [isLoading, setIsloading] = useState(false);
  // Function that handles the logout request
  const onSubmit = handleSubmit(async (data) => {
    let membresias = [...options?.membershipOptions];

    let values = [
      { name: planTypes?.CLASSIC, value: data?.CLASSIC },
      { name: planTypes?.GOLD, value: data?.GOLD },
      { name: planTypes?.MOTORIDER, value: data?.MOTORIDER },
      { name: planTypes?.PLUS, value: data?.PLUS },
      { name: planTypes?.PLUSF4, value: data?.PLUSF4 },
      { name: planTypes?.GOLDF4, value: data?.GOLDF4 },
      { name: planTypes?.MOTORIDERAV, value: data?.MOTORIDERAV },
      { name: planTypes?.RETRO, value: data?.RETRO },
      { name: planTypes?.GOLDENP, value: data?.GOLDENP },
    ];

    let appliesToMemberships = [];

    for (let i = 0; i < membresias?.length; i++) {
      for (let j = 0; j < values.length; j++) {
        if (membresias[i]?.name === values[j]?.name && values[j]?.value) {
          appliesToMemberships.push(membresias[i]?._id);
        }
      }
    }

    let dataToPost = { ...data };

    dataToPost.appliesToMemberships = appliesToMemberships;
    dataToPost.validFrom = moment
      .utc(dataToPost?.validFrom)
      .format('YYYY-MM-DD');
    dataToPost.validFrom = new Date(
      dataToPost.validFrom + 'T' + dataToPost?.fromTime
    );

    dataToPost.validTo = moment.utc(dataToPost?.validTo).format('YYYY-MM-DD');
    dataToPost.validTo = new Date(
      dataToPost.validTo + 'T' + dataToPost?.toTime
    );

    delete dataToPost.fromTime;
    delete dataToPost.toTime;
    delete dataToPost.CLASSIC;
    delete dataToPost.GOLD;
    delete dataToPost.MOTORIDER;
    delete dataToPost.PLUS;
    delete dataToPost.PLUSF4;
    delete dataToPost.MOTORIDERAV;
    delete dataToPost.GOLDF4;
    delete dataToPost.RETRO;
    delete dataToPost.GOLDENP;


    if (!isLoading) {
      setIsloading(true);
      discountCodesServices
        .createDiscountCodes(filterParams(dataToPost))
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Codigo creado con éxito`, 'success');
            setIsloading(false);
            handleModal();
            close();
            getCodes();
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const transformToUppercase = () => {
    setValue('code', watch('code').toUpperCase());
  };

  const close = () => {
    handleModal();
    reset();
  };

  return (
    <Modal size="lg" centered show={modal?.isOpen} onHide={() => close()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>Nuevo código de descuento</h5>
        <CreateCodeForm
          t={t}
          register={register}
          errors={errors}
          action={onSubmit}
          cancel={() => close()}
          control={control}
          options={options}
          onChange={{ transformToUppercase: transformToUppercase }}
          cancelOptions={modal?.cancelOptions}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const CreateGiftcardModal = (props) => {
  const { modal, handleModal, options, getCodes } = props;

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(schemas.createGiftcardSaleSchema(t)),
  });
  const [isLoading, setIsloading] = useState(false);
  const [limitGiftcard, setLimitGiftcard] = useState('');
  const [publicCodes, setPublicCodes] = useState([]);

  // Function that handles the logout request
  const onSubmit = handleSubmit(async (data) => {
    let dataToPost = { ...data };
    validateLimitGiftcards();

    if (dataToPost?.saleType === giftCardType?.PARTICULAR) {
      delete dataToPost.saleQuantity;
      dataToPost.customer = {
        firstName: dataToPost?.firstName,
        lastName: dataToPost?.lastName,
        identificationType: dataToPost?.identificationType,
        identification: dataToPost?.identification,
        phonePrefix: dataToPost?.phonePrefix,
        phoneNumber: dataToPost?.phoneNumber,
        email: dataToPost?.email,
      };
    }

    if (dataToPost?.saleType === giftCardType?.RETAIL) {
      delete dataToPost.publicCode;
      if (dataToPost.saleQuantity === '0') {
        notify(`No existen tarjetas activas dispobibles`, 'info');
        return null;
      }
    }

    delete dataToPost.firstName;
    delete dataToPost.lastName;
    delete dataToPost.identificationType;
    delete dataToPost.identification;
    delete dataToPost.phonePrefix;
    delete dataToPost.phoneNumber;
    delete dataToPost.email;

    if (!isLoading) {
      setIsloading(true);
      giftCardService
        .createGiftcardSale(filterParams(dataToPost))
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Giftcard creada con éxito`, 'success');
            setIsloading(false);
            handleModal();
            close();
            getCodes();
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const close = () => {
    handleModal();
    reset();
  };

  const getActiveGiftcards = (saleChannelId, type, membership) => {
    setLimitGiftcard('');
    setValue('saleQuantity', 0);
    setPublicCodes([]);

    let params = {
      saleChannel: saleChannelId,
      type: type,
      membership: membership,
    };

    if (saleChannelId && type) {
      if (type === 'Retail' && !membership) {
        return null;
      }

      giftCardService
        .getActiveGiftcards(params)
        .then((response) => {
          if ([200, 201].includes(response?.status)) {
            setLimitGiftcard(response?.data?.quantity);
            setValue('saleQuantity', response?.data?.quantity);
            if (response?.data?.publicCodes?.length > 0) {
              let data = [];
              for (let i = 0; i < response?.data?.publicCodes.length; i++) {
                data.push({
                  name: `${response?.data?.publicCodes[i]?.publicCode}`,
                  _id: response?.data?.publicCodes[i]?.publicCode,
                  membershipId: response?.data?.publicCodes[i]?.membershipId,
                });
              }
              data = data?.filter((d) => d?.membershipId === membership);
              setPublicCodes(data);
            }
          } else {
            notify(`${response.message}` || 'error', 'error');
            setLimitGiftcard('0');
            setValue('saleQuantity', 0);
            notify(`No existen tarjetas activas dispobibles`, 'info');
          }
        })
        .catch(() => { });
    }
  };

  const validateLimitGiftcards = () => {
    if (parseFloat(watch('saleQuantity')) > limitGiftcard) {
      notify('No puede superar el limite de tarjetas permitidas', 'info');
      setValue('saleQuantity', limitGiftcard);
    }
    return null;
  };

  const selectPublicCode = () => {
    let [filtered] = publicCodes?.filter((p) => p?._id == watch('publicCode'));
    setValue('membership', filtered?.membershipId);
  };

  options.publicCodes = publicCodes;

  return (
    <Modal size="lg" centered show={modal?.isOpen} onHide={() => close()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>Nueva Venta</h5>
        <CreateGiftcardSaleForm
          t={t}
          register={register}
          errors={errors}
          action={onSubmit}
          cancel={() => close()}
          control={control}
          options={options}
          cancelOptions={modal?.cancelOptions}
          onChange={{
            getActiveGiftcards: getActiveGiftcards,
            validateLimitGiftcards: validateLimitGiftcards,
            selectPublicCode: selectPublicCode,
          }}
          watch={watch}
          limit={{
            limitGiftcard: limitGiftcard,
            setPublicCodes: setPublicCodes,
          }}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const DeleteGiftcardModal = (props) => {
  const { modal, handleModal, action } = props;

  const close = () => {
    handleModal();
  };

  return (
    <Modal size="lg" centered show={modal?.isOpen} onHide={() => close()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>Eliminar venta de Giftcard</h5>
        <br />
        <h6>
          Esta seguro que desea eliminar la venta de giftcard{' '}
          <b>{modal?.selected?.publicCode}</b>
        </h6>
        <br />
        <div className="buttons-center-container">
          <AltButton action={() => close()} label={'Cancelar'} />
          <ActionButton action={action} label={'Si, Eliminar'} />
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const EditGiftcardModal = (props) => {
  const {
    modal,
    options,
    action,
    close,
    register,
    watch,
    errors,
    control,
    onChange,
  } = props;


  // Function that handles the logout request

  return (
    <Modal size="lg" centered show={modal?.isOpen} onHide={() => close()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>Editar Venta</h5>
        <EditGiftcardSaleForm
          register={register}
          errors={errors}
          action={action}
          cancel={() => close()}
          control={control}
          options={options}
          cancelOptions={modal?.cancelOptions}
          onChange={onChange}
          watch={watch}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const EditDiscountCodeModal = (props) => {
  const { modal, options, errors, control, register, close, action } = props;

  return (
    <Modal size="lg" centered show={modal?.isOpen} onHide={() => close()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>Editar código de descuento</h5>
        <EditCodeForm
          register={register}
          errors={errors}
          action={action}
          cancel={() => close()}
          control={control}
          options={options}
          cancelOptions={modal?.cancelOptions}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const CreateGiftcardLoadModal = (props) => {
  const { handleModal, modal, getCodes } = props;

  const [canPost, setCanPost] = useState(true);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        // Do whatever you want with the file contents
      };
      reader.readAsArrayBuffer(file);

      if (!['text/csv'].includes(file.type)) {
        notify('Agregar un formato valido de imagen', 'error');
        return null;
      }
      let maxWeight = 5;
      if (file.size * 0.000001 > maxWeight) {
        notify(`La imagen no debe superar los ${maxWeight} MB`, 'error');
      } else {
        setFileUploaded(file);
      }
    });
  }, []);

  const appendFile = (file) => {
    const formData = new FormData();
    formData.append('csvFile', file);
    return formData;
  };

  const downloadAsCSV = (data) => {

    let dataToPrint = []
    for (let i = 0; i < data.length; i++) {
      dataToPrint?.push({
        plan: data[i]?.plan,
        public_code: data[i]?.public_code,
        private_code: data[i]?.private_code,
      })
    }
    exportDataToExcel(dataToPrint, 'codigos_giftcards.xlsx')
  };

  const sendCSVImage = async () => {
    let fileToPost = appendFile(fileUploaded);

    if (canPost) {
      setCanPost(false);
      await giftCardService
        .uploadGiftcardCodes(fileToPost)
        .then((response) => {
          downloadAsCSV(response.data)
          if ([200, 201].includes(response.status)) {
            notify(`Carga realizada con exito`, 'success');
            close();
            setFileUploaded('');
            setCanPost(true);
            getCodes();
          } else {
            notify(`${response.message}`, 'error');
            setCanPost(true);
          }
        });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const [fileUploaded, setFileUploaded] = useState('');

  const close = () => {
    handleModal();
    setFileUploaded('');
  };

  return (
    <Modal size="lg" centered show={modal?.isOpen} onHide={() => close()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5 style={{ textAlign: 'center' }}>
          Adjuntar archivo de códigos gifcard
          <br /> <b>(CSV)</b>
        </h5>
        <div className="">
          <div className="dropzone-container">
            {fileUploaded ? (
              <FileComponent
                file={fileUploaded}
                remove={() => setFileUploaded('')}
              />
            ) : (
              <Dropzone
                getRootProps={getRootProps}
                getInputProps={getInputProps}
                message={'Adjuntar documento CSV'}
              />
            )}
          </div>
        </div>
        <br />
        <div className="buttons-center-container">
          <AltButton action={() => close()} label={'Cancelar'} />
          <ActionButton action={sendCSVImage} label={'Guardar'} />
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const ActivateGiftcardModal = (props) => {
  const { handleModal, modal, options, register, errors, action } = props;

  const close = () => {
    handleModal();
  };

  return (
    <Modal size="lg" centered show={modal?.isOpen} onHide={() => close()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5 style={{ textAlign: 'center' }}>
          Selecciona el canal de las <br /> Giftcards que deseas activar
        </h5>
        <ActivateGiftcardForm
          register={register}
          errors={errors}
          action={action}
          cancel={() => close()}
          options={options}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const BlockGiftcardModal = (props) => {
  const { handleModal, modal, action } = props;

  const close = () => {
    handleModal();
  };

  return (
    <Modal size="lg" centered show={modal?.isOpen} onHide={() => close()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5 style={{ textAlign: 'center' }}>
          ¿Está seguro que desea bloquear las tarjetas seleccionadas ?
        </h5>
        <br />
        <br />
      </Modal.Body>

      <div className="buttons-center-container">
        <AltButton label={'No'} action={() => close()} />
        <ActionButton label={'Bloquear Tarjetas'} action={() => action()} />
      </div>

      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const CSVErrorsModal = (props) => {
  const { handleModal, modal } = props;

  const close = () => {
    handleModal();
  };

  return (
    <Modal size="lg" centered show={modal?.isOpen} onHide={() => close()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5 style={{ textAlign: 'center' }}>
          El archivo CSV contiene <b>{modal?.errors?.length}</b> errores
        </h5>
        <br />

        {modal?.errors?.length > 0 && (
          <ol>
            {modal?.errors?.map((e) => (
              <li key={e?.message}>
                {e?.message} <b>fila:{e?.row}</b>{' '}
              </li>
            ))}
          </ol>
        )}
      </Modal.Body>

      <div className="buttons-center-container">
        <ActionButton label={'Aceptar'} action={() => close()} />
      </div>

      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const IGTFPaymentModal = (props) => {
  const { handleModal, modal, acceptIGTFMethod, denyIGTFMethod } = props;

  const close = () => {
    handleModal();
  };

  const patchName = (method) => {
    if (method === paymentMethods?.TDC) {
      return 'TARJETA INTERNACIONAL';
    } else {
      return method;
    }
  };

  return (
    <Modal size="lg" centered show={modal?.isOpen} onHide={() => close()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5 style={{ textAlign: 'center' }}>
          ¿Desea cancelar la totalidad del monto y el recargo por IGTF en{' '}
          {patchName(modal?.method)}?
        </h5>
        <br />
        <h6>
          Monto {modal?.amount1}$ + IGTF {(modal?.amount1 * 0.03).toFixed(2)}$ ={' '}
          <b style={{ fontSize: '24px' }}>
            {(modal?.amount1 * 1.03).toFixed(2)}$
          </b>
        </h6>
        <br />
      </Modal.Body>

      <p style={{ textAlign: 'center' }}>
        * En caso de seleccionar "No", el recargo de IGTF se cobrara en un
        segundo metodo de pago
      </p>
      <div className="buttons-center-container">
        <AltButton label={'No'} action={() => denyIGTFMethod()} />
        <ActionButton label={'Si'} action={() => acceptIGTFMethod()} />

        {/* <div className="buttons-center-container">
        <ActionButton label={'Aceptar'} action={() => handleModal()} /> */}
      </div>

      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};


export const CurrencyChangeHistoryModal = (props) => {
  const { handleModal, handleDateChange, modal } = props;

  return (
    <Modal size="lg" centered show={modal?.isOpen} onHide={() => handleModal()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5 style={{ textAlign: 'center' }}>Historico de Tasas</h5>
        <FilterDatePicker
          bootstrap={'col-3'}
          label={''}
          type={'date'}
          name={'untilDate'}
          placeholder={''}
          onChange={handleDateChange}
          value={modal?.date}
        />{' '}
        <div className="table-container">
          <table style={{ textAlign: 'center' }} className="table">
            <tr>
              <th>Fecha</th>
              <th>Tasa del Dia</th>
            </tr>
            {modal?.data?.length > 0 &&
              modal?.data?.map((d) => (
                <tr key={d?._id}>
                  <td>{moment.utc(d?.createdAt).format('DD/MM/YYYY LTS')}</td>

                  <td>{d?.rate}</td>
                </tr>
              ))}
          </table>
        </div>
      </Modal.Body>

      <div className="buttons-center-container">
        <ActionButton label={'Aceptar'} action={() => handleModal()} />
      </div>

      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const EditHistoryModal = (props) => {
  const { handlePageChange, handleModal, handleDateTo, handleDateFrom, modal } = props;


  return (
    <Modal size="xl" centered show={modal?.isOpen} onHide={() => handleModal()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5 style={{ textAlign: 'center' }}>Historial de Actualizaciones</h5>
        <div className="row" style={{ width: "100%" }}>
          <div className="col-3"></div>
          <FilterDatePicker
            bootstrap={'col-3'}
            label={'Desde'}
            type={'date'}
            name={'dateFrom'}
            placeholder={''}
            onChange={handleDateFrom}
            value={new Date(modal?.dateFrom)}
          />{' '}
          <FilterDatePicker
            bootstrap={'col-3'}
            label={'Hasta'}
            type={'date'}
            name={'dateTo'}
            placeholder={''}
            onChange={handleDateTo}
            value={new Date(modal?.dateTo)}
          />{' '}
          <div className="col-3"></div>
        </div>
        <div className="table-container">
          <table style={{ textAlign: 'center' }} className="table">
            <tr>
              <td>Fecha y hora del cambio</td>
              <td>Asesor</td>
              <td>N. Orden</td>
              <td>Campo modificado</td>
              <td>Dato anterior</td>
              <td>Dato actualizado</td>
            </tr>
            {modal?.data?.length > 0 &&
              modal?.data?.map((d) => (
                <tr key={d?._id}>
                  <td>{d?.date ? moment.utc(d?.date).format('DD/MM/YYYY LTS') : ""}</td>
                  <td>{capitalize(d?.user?.firstName) + " " + capitalize(d?.user?.lastName)}</td>
                  <td>{d?.memId?.membershipId}</td>
                  <td>{d?.changes?.map(c => <>{c?.field} <br /></>)}</td>
                  <td>{d?.changes?.map(c => <>{displayChange(c, c?.field, "from")} <br /></>)}</td>
                  <td>{d?.changes?.map(c => <>{displayChange(c, c?.field, "to")} <br /></>)}</td>
                </tr>
              ))}
            {modal?.data?.length === 0 && <tr><td colSpan="6">No hay registros</td></tr>}
          </table>
        </div>
      </Modal.Body>
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(modal?.count / modal?.limit)}
          variant="outlined"
          shape="rounded"
          page={modal?.page}
          onChange={handlePageChange}
        />
      </div>

      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

