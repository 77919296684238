import { authInstance } from './axios'
let url = process.env.REACT_APP_API_URL

export const paymentsService = {
  createMembershipPayment: async (data) => {
    const response = await authInstance
      .post(`${url}/payments/createMembershipPayment`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        console.log('error', error)
        error.data = []
        return error
      })
    return response
  },

  editPayment: async (data) => {
    const response = await authInstance
      .put(`${url}/payments/update`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        console.log('error', error)
        error.data = []
        return error
      })
    return response
  },

  createPendingPayment: async (data) => {
    const response = await authInstance
      .post(`${url}/payments/createMembershipPendingPayment`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        console.log('error', error)
        error.data = []
        return error
      })
    return response
  },
}
