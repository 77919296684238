import { useState, useEffect } from 'react';
import { Header } from '../../../common/Header/Header.jsx';
import { useHistory, useParams } from 'react-router-dom';
import { membershipService } from '../../../../services/membershipService.js';
import { notify } from '../../../../assets/helpers/toast.js';
import { OrdeCard, OrderCard } from './OrderComponents.jsx';
import { ClientInfoCard } from './ClientInfoCard.jsx';
import { UpgradeInfoCard } from './UpgradeInfoCard.jsx';
import { VehicleInfoCard } from './VehicleInfoCard.jsx';
import { AfiliationCard } from './AfiliationCard.jsx';
import { SalesButtons } from '../../../common/Buttons/Buttons.jsx';
import { salesMembershipStatus } from '../../../../assets/helpers/options.js';
import { renameMembershipStatus } from '../../../../assets/helpers/funciones.js';
import { LoadSpinner } from '../../../common/LoadSpinner/LoadSpinner.jsx';
import './Order.scss';

export const UpgradeOrder = () => {
  const { membershipId } = useParams();
  const [membership, setMembership] = useState('');
  const [isLoading, setIsloading] = useState(false);

  const history = useHistory();

  const getSalesMembershipById = (membershipId) => {
    membershipService
      .getSalesMembershipById(membershipId)
      .then((response) => {
        if ([200, 201].includes(response?.status)) {
          setMembership(response?.data);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch((error) => {});
  };

  const renewMembership = (membershipId) => {
    if (!isLoading) {
      setIsloading(true);
      membershipService
        .renewMembership(membershipId)
        .then((response) => {
          if ([200, 201].includes(response?.status)) {
            setIsloading(false);
            history.push(`/afiliations/edit-order/${response?.data?._id}`);
            window.location.reload();
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  };

  const reloadMembership = () => {
    getSalesMembershipById(membershipId);
  };

  const rescueMembership = () => {
    if (!isLoading) {
      setIsloading(true);
      membershipService
        .rescueMembership(membershipId)
        .then((response) => {
          if ([200, 201].includes(response?.status)) {
            setIsloading(false);
            history.push(`/afiliations/edit-order/${response?.data?._id}`);
            window.location.reload();
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  };

  useEffect(() => {
    getSalesMembershipById(membershipId);
  }, []);

  return (
    <>
      <Header />
      <LoadSpinner show={isLoading} />
      {membership && (
        <div className="service-with-container container">
          <h1 className="title-component">
            Upgrade Orden Nro. {membership?.membershipId} (
            {renameMembershipStatus(membership?.membershipStatus)})
          </h1>
          <div className="breadcrumb-container"></div>
          <div className="filter-button-container"></div>
          <br />
          <div className="row">
            <div className="col-12 col-lg-9">
              <UpgradeInfoCard
                label={'INFORMACIÓN DE RENOVACION'}
                membership={membership}
                reloadMembership={reloadMembership}
              />
            </div>
            <div className="col-12 col-lg-3">
              <OrderCard
                membership={membership}
                refresh={() => getSalesMembershipById(membershipId)}
                hideCancelButton={true}
              />{' '}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
