import React, { useState, useCallback } from 'react';
import search from '../../../../images/search.svg';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Controller } from 'react-hook-form';
import es from 'date-fns/locale/es';
import 'react-datepicker/dist/react-datepicker.css';
import {
  capitalize,
  translateStatus,
} from '../../../../assets/helpers/funciones';
import { providerStatus } from '../../../../assets/helpers/options';
import copyImage from '../../../../images/copyImage.svg';
import checkbox from '../../../../images/checkbox.svg';
import checkboxChecked from '../../../../images/checkboxChecked.svg';
import { Dropzone, FileComponent } from '../../Dropzone/Dropzone';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDropzone } from 'react-dropzone';
import { paymentType } from '../../../../assets/helpers/options';
import { notify } from '../../../../assets/helpers/toast';

import './Input.scss';
registerLocale('es', es);

export const FilterDatePicker = (props) => {
  const {
    label,
    bootstrap,
    minDate,
    maxDate,
    data,
    name,
    placeholder,
    value,
    onChange,
    disabled,
  } = props;
  return (
    <div className={`input-component-container ${bootstrap}`}>
      {data?.subLabel && !label ? '' : <label htmlFor={name}>{label}</label>}

      <div>
        <div className="input-sublabel-container">
          {data?.subLabel && <h6>{data?.subLabel}</h6>}
          <DatePicker
            dateFormat="dd/MM/yyyy"
            placeholderText={placeholder}
            onChange={onChange}
            selected={value}
            name="Hasta"
            locale="es"
            autoComplete="off"
            minDate={minDate || data?.minDate}
            maxDate={maxDate || data?.maxDate}
            disabled={disabled || data?.disabled}
          />
        </div>
      </div>
    </div>
  );
};

// EYE component that shows or hyde password
export const ShowPassword = (props) => {
  const { type, inputType, showPassword } = props;

  if (type === 'password' && inputType !== 'text') {
    return <ion-icon name="eye-outline" onClick={showPassword}></ion-icon>;
  }
  if (type === 'password' && inputType === 'text') {
    return <ion-icon name="eye-off-outline" onClick={showPassword}></ion-icon>;
  } else return null;
};

export const SelectInput = (props) => {
  const {
    onChange,
    bootstrap,
    label,
    name,
    register,
    options,
    errors,
    disabled,
    capitalizeOff
  } = props;

  const { ref } = register(name);

  return (
    <div className={`input-component-container ${bootstrap}`}>
      <label htmlFor={name}>{label}</label>
      <div>
        <select
          className="input-select"
          onChange={onChange}
          {...register(name, {
            onChange: onChange,
          })}
          name={name}
          disabled={disabled}
        >
          <option value=""></option>
          {options?.map((o, i) => (
            <option key={o?._id} value={o?._id}>
              {capitalizeOff ? o?.name : capitalize(o?.name?.toLowerCase())}
            </option>
          ))}
        </select>
      </div>

      <p>{errors}</p>
    </div>
  );
};

export const Input = (props) => {
  const {
    bootstrap,
    label,
    type,
    title,
    name,
    register,
    placeholder,
    options,
    errors,
    data,
    subLabel,
    control,
    onChange,
    disabled,
    min,
    max,
    minDate,
    maxDate,
    capitalizeOff
  } = props;

  const [inputType, setInputType] = useState(type);

  const showPassword = () => {
    inputType === 'password' ? setInputType('text') : setInputType('password');
  };

  let errorInput = 'i';
  if (errors) {
    errorInput = 'error-input';
  }

  if (title) {
    return (
      <>
        <div
          style={{ marginTop: '30px' }}
          className="filter-button-container"
        ></div>
        <h5 className="form-input-title">{title}</h5>
      </>
    );
  }

  if (type === 'checkbox') {
    return (
      <CheckboxInput
        subLabel={data?.subLabel || subLabel}
        bootstrap={bootstrap}
        label={label}
        name={name}
        register={register}
        errorInput={errorInput}
        errors={errors}
        onChange={onChange || data?.onChange}
        disabled={disabled || data?.disabled}
      />
    );
  }

  if (type === 'select') {
    return (
      <SelectInput
        options={data?.options || options}
        bootstrap={bootstrap}
        label={label}
        type={type}
        name={name}
        register={register}
        placeholder={placeholder}
        errors={errors}
        disabled={disabled || data?.disabled}
        onChange={onChange || data?.onChange}
        capitalizeOff={capitalizeOff}
      />
    );
  }

  if (type === 'textarea') {
    return (
      <div className={`input-component-container ${bootstrap}`}>
        {data?.subLabel && !label ? '' : <label htmlFor={name}>{label}</label>}

        <div>
          <div className="input-sublabel-container">
            {data?.subLabel && <h6>{data?.subLabel}</h6>}
            <textarea
              id={name}
              className={`${errorInput}`}
              type={inputType}
              name={name}
              onChange={onChange}
              {...register(name, {
                onChange: onChange,
              })}
              placeholder={placeholder}
              autoComplete="off"
              disabled={disabled || data?.disabled}
            />
          </div>
        </div>

        <p>{errors}</p>
      </div>
    );
  }

  if (type === 'date') {
    return (
      <div className={`input-component-container ${bootstrap}`}>
        {data?.subLabel && !label ? '' : <label htmlFor={name}>{label}</label>}

        <div>
          <div className="input-sublabel-container">
            {data?.subLabel && <h6>{data?.subLabel}</h6>}
            <Controller
              control={control}
              name={name}
              render={({ field }) => (
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  placeholderText={'DD/MM/AAAA'}
                  onChange={(date) => field.onChange(date)}
                  selected={field.value}
                  locale="es"
                  autoComplete="off"
                  minDate={minDate || data?.minDate}
                  maxDate={maxDate || data?.maxDate}
                  disabled={disabled || data?.disabled}
                />
              )}
            />
          </div>
        </div>

        <p>{errors}</p>
      </div>
    );
  }
  if (type === 'birthday') {
    return (
      <div className={`input-component-container ${bootstrap}`}>
        {data?.subLabel && !label ? '' : <label htmlFor={name}>{label}</label>}

        <div>
          <div className="input-sublabel-container">
            {data?.subLabel && <h6>{data?.subLabel}</h6>}
            <input
              id={name}
              className={`${errorInput}`}
              type={'date'}
              name={name}
              onChange={onChange}
              {...register(name, {
                onChange: onChange,
              })}
              placeholder={placeholder}
              autoComplete="off"
              disabled={disabled || data?.disabled}
              min={min || data?.min}
              max={max || data?.max}
            />
          </div>
        </div>

        <p>{errors}</p>
      </div>
    );
  }

  if (name) {
    return (
      <div className={`input-component-container ${bootstrap}`}>
        {data?.subLabel && !label ? '' : <label htmlFor={name}>{label}</label>}

        <div>
          <div className="input-sublabel-container">
            {data?.subLabel && <h6>{data?.subLabel}</h6>}
            <input
              id={name}
              className={`${errorInput}`}
              type={inputType}
              name={name}
              onChange={onChange}
              {...register(name, {
                onChange: onChange,
              })}
              placeholder={placeholder}
              autoComplete="off"
              disabled={disabled || data?.disabled}
              min={min || data?.min}
              max={max || data?.max}
            />
          </div>

          <ShowPassword
            type={type}
            inputType={inputType}
            showPassword={showPassword}
          />
        </div>

        <p>{errors}</p>
      </div>
    );
  } else {
    return <div className={`input-component-container ${bootstrap}`}></div>;
  }
};

export const SelectPerPage = (props) => {
  const { options, label, value, onChange } = props;
  return (
    <div className="select-per-page">
      <label htmlFor="label">{label}</label>
      <select name="" id="label" onChange={(e) => onChange(e)} value={value}>
        {options?.map((o) => (
          <option key={o} value={o}>
            {o}
          </option>
        ))}
      </select>
    </div>
  );
};

export const Select = (props) => {
  const { options, label, value, onChange, disabled } = props;
  return (
    <div className="simple-select">
      <label htmlFor="label">{label}</label>
      <select
        disabled={disabled}
        name=""
        id="label"
        onChange={(e) => onChange(e)}
        value={value}
      >
        {options?.map((o) => (
          <option key={o?.name} value={o?._id}>
            {o?.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export const CheckboxInput = (props) => {
  const {
    bootstrap,
    label,
    subLabel,
    errorInput,
    name,
    register,
    errors,
    onChange,
    disabled
  } = props;

  return (
    <div
      className={`checkbox-input-container input-component-container ${bootstrap}`}
    >
      {label ? <label htmlFor={name}>{label}</label> : ''}
      <div className="checkbox-input-sublabel-container">
        <input
          id={name}
          className={`checkbox-input  ${errorInput}`}
          type={'checkbox'}
          name={name}
          onChange={onChange}
          {...register(name, {
            onChange: onChange,
          })}
          autoComplete="off"
          disabled={disabled}
        />
        <h6>{subLabel}</h6>
      </div>
      {errors && <p>{errors}</p>}
    </div>
  );
};

export const SearchInput = (props) => {
  const { placeholder, width, value, onChange, searchAction } = props;

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // 👇 Get input value
      searchAction();
    }
  };

  return (
    <div className="incidence-search">
      <div className="searchImageContainers">
        <img src={search} alt="search" onClick={() => searchAction()} />
      </div>
      <input
        style={{ width: width }}
        className="service-search-input"
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e)}
        onKeyDown={(e) => handleKeyDown(e)}
      />
    </div>
  );
};

export const SelectProvider = (props) => {
  const { onChange, bootstrap, label, name, options, errors, value } = props;

  return (
    <div className={`input-component-container ${bootstrap}`}>
      <label htmlFor={name}>{label}</label>
      <div>
        <select
          className="input-select"
          onChange={onChange}
          value={value}
          name={name}
        >
          <option value=""></option>
          {options?.map((o, i) => {
            if (o?.status === providerStatus.available) {
              return (
                <option key={o?._id} value={o?._id}>
                  {capitalize(o?.contactName?.toLowerCase())}
                  &nbsp;&nbsp;(
                  {translateStatus(o?.status)})
                </option>
              );
            } else {
              return (
                <option style={{ color: 'red' }} key={o?._id} value={o?._id}>
                  {capitalize(o?.contactName?.toLowerCase()) + '   '}
                  &nbsp;&nbsp;(
                  {translateStatus(o?.status)})
                </option>
              );
            }
          })}
        </select>
      </div>

      <p>{errors}</p>
    </div>
  );
};

export const PaymentSection = (props) => {
  const {
    payment,
    watch,
    options,
    register,
    index,
    returnAccountData,
    fileUploaded,
    setFileUploaded,
    onChange,
  } = props;

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        // Do whatever you want with the file contents

      };
      reader.readAsArrayBuffer(file);

      if (
        !['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(
          file.type
        )
      ) {
        notify('Agregar un formato valido de imagen', 'error');
        return null;
      }
      let maxWeight = 3;
      if (file.size * 0.000001 > maxWeight) {
        notify(`La imagen no debe superar los ${maxWeight} MB`, 'error');
      } else {
        let files = fileUploaded;
        files[index] = acceptedFiles[0];

        setFileUploaded([...files]);
      }
    });
  }, []);

  const removeFile = (index) => {
    let files = fileUploaded;
    files[index] = '';
    setFileUploaded([...files]);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const validateOnChangeUSD_BsS = (name) => {
    if (name?.includes('amount') && !name?.includes('BsS')) {
      return onChange?.handleAmountChange;
    }
  };

  return (
    <>
      {payment?.label === 'IGTF' ? (
        <IGTFComponent
          amount={watch(`amount${payment?.counter}`)}
          method={
            options?.paymentMethods?.filter(
              (o) => o?._id === watch(`method${payment?.counter}`)
            )[0]
          }
        />
      ) : (
        <Input
          key={payment?.label}
          bootstrap={payment?.bootstrap}
          label={payment?.label}
          type={payment?.type}
          name={payment?.name}
          register={register}
          placeholder={''}
          errors={payment?.errors}
          options={options?.paymentMethods}
          min={'1'}
          disabled={payment?.disabled}
          onChange={validateOnChangeUSD_BsS(payment?.name)}
        />
      )}

      {watch(`${payment?.name}`) && payment?.type === 'select' && (
        <CopyToClipboard
          text={returnAccountData(
            options.paymentMethods,
            watch(`${payment?.name}`)
          )}
        >
          <div className="col-5 copy-payment-data-image-button-container">
            <img
              className="copy-payment-data-image-button"
              src={copyImage}
              alt="copyImage"
            />
            <h6>Copiar datos de pago</h6>
          </div>
        </CopyToClipboard>
      )}

      {payment?.dropzone && (
        <div className="col-12">
          <label className="dropzone-label">{payment?.label}</label>
          {fileUploaded[index] ? (
            <FileComponent
              file={fileUploaded[index]}
              remove={() => removeFile(index)}
            />
          ) : (
            <Dropzone
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              message={'(PNG, JPG, JEPG, máx. 3MB)'}
            />
          )}
        </div>
      )}
    </>
  );
};

export const CheckboxComponent = (props) => {
  const { label, selected, onSelect } = props;
  return (
    <div className="checkbox-component-container">
      <h6 htmlFor="">{label}</h6>
      {selected ? (
        <img onClick={() => onSelect(!selected)} src={checkboxChecked} alt="" />
      ) : (
        <img onClick={() => onSelect(!selected)} src={checkbox} alt="" />
      )}
    </div>
  );
};

export const ValidateCheckboxComponent = (props) => {
  const { label, selected, onSelect } = props;
  return (
    <div className="checkbox-component-container">
      <h6 htmlFor="">{label}</h6>
      {selected ? (
        <img onClick={() => onSelect(label)} src={checkboxChecked} alt="" />
      ) : (
        <img onClick={() => onSelect(label)} src={checkbox} alt="" />
      )}
    </div>
  );
};

export const IGTFComponent = (props) => {
  const { method, amount } = props;

  if (amount && method && method?.currency !== 'BsS') {
    return (
      <div className="monto-con-igtf col-12">
        <h6>Monto con recargo de IGTF 3%</h6>
        <h5>
          {(parseFloat(amount) + parseFloat(amount) * 0.03)?.toFixed(2)} (USD)
        </h5>
      </div>
    );
  } else return null;
};
